import React, {FC, useEffect} from 'react';
import Lottie from 'lottie-react-web';

interface AstronautLottieProps {
	style?: React.CSSProperties;
}

const AstronautLottie: FC<AstronautLottieProps> = ({style = {}}) => {
	// const astronautLottie = require('../assets/lottie/astronaut.json');
	const [json, setJson] = React.useState(null);

	useEffect(() => {
		async function load() {
			const _json = await import('../public/assets/onboarding/astronaut.json');
			setJson(_json);
		}

		load();
	}, [])
	return json && (
		<Lottie options={{animationData: json, loop: true}} style={style}/>
	);
};

export default AstronautLottie;
