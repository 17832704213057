import '../styles/globals.scss';
import '../components/style.css'
import "../public/assets/bootstrap/bootstrap-grid.css";
import "react-circular-progressbar/dist/styles.css";
import "plyr-react/plyr.css";
import "react-phone-input-2/lib/style.css";
import "react-image-lightbox/style.css";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { useRouter } from "next/router";
import React, {Suspense, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Image from 'next/image';
import useAuth from '../hooks/useAuth';
import {useMediaQuery} from "react-responsive";
import {logoDark} from '../public/assets';
import ChooseGrade from '../components/global/ChooseGrade';
import OnBoardingFlow from '../containers/boardingFlow';
import GuestBottomDrawer from '../components/global/GuestBottomDrawer';
import {getCurrentVersion, commonPages, publicPages} from '../helpers';
import AskNameModal from '../components/global/AskNameModal';
import BlazeBookingPopup from '../containers/blaze/BookingPopup';
import useIsNight from '../hooks/useIsNight';
import UserBadgesModal from '../components/global/UserBadgesModal';
import StoryModal from '../components/home/story/StoryModal';

const LiveSessionLayout = dynamic(() => import('../layouts/LiveSessionLayout'))
const BlazeLayout = dynamic(() => import('../layouts/BlazeLayout'))
const Navbar = dynamic(() => import('../containers/global/navbar'));
const BlazeCallNotification = dynamic(() => import('../containers/global/blaze-call-notification'));
const AppModal = dynamic(() => import('../components/global/AppModal'))
// const PushUpdateNotification = dynamic(() => import('../components/global/PushUpdateNotification'))
const SnackBar = dynamic(() => import('../components/global/SnackBar'))
const GetProWarning = dynamic(() => import('../components/global/GetProWarning'))
const FloatingPlayerContainer = dynamic(() => import('../containers/livesession/FloatingPlayerContainer'))

const UserContextProvider = dynamic(() => import("../context/global/user-context"));
const PustackProContextProvider = dynamic(() => import("../context/global/PustackProContext"));
const IntroContextProvider = dynamic(() => import("../context/global/IntroContext"));
const ThemeContextProvider = dynamic(() => import("../context/global/ThemeContext"));
const SidebarContextProvider = dynamic(() => import("../context/global/SidebarContext"));
const SubjectModalContextProvider = dynamic(() => import("../context/global/SubjectModal"));
const BookSessionContextProvider = dynamic(() => import("../context/blaze/BookSessionContext"));
const SnackbarContextProvider = dynamic(() => import("../context/snackbar"));
const NavbarContextProvider = dynamic(() => import("../context/global/NavbarContext"));
const CmsContextProvider = dynamic(() => import("../context/cms/CmsContext"));
const BlazeSessionContextProvider = dynamic(() => import("../context/blaze/BlazeSessionContext"));
const ClassroomContextProvider = dynamic(() => import("../context/classroom/index"));
const TipContextProvider = dynamic(() => import("../context/tips/index"));
const PracticeContextProvider = dynamic(() => import("../context/practice/index"));
const LiveSessionContextProvider = dynamic(() => import("../context/livesessions/LiveSessionContext"));
const ModalContextProvider = dynamic(() => import("../context/global/ModalContext"));
const PustackProSlider = dynamic(() => import("../containers/global/pro-slider-old"));
const PustackDoubtSlider = dynamic(() => import("../containers/global/pro-slider"));
const DesktopLanding = dynamic(() => import("../components/PustackLanding"));
const MobileLanding = dynamic(() => import("../components/MobileLanding"));

// const commonPages = [
//   {path: "/sitemap", noLoader: true},
//   {path: "/contact", noLoader: true},
//   {path: "/about", noLoader: true},
//   {path: "/privacy_policy", noLoader: true},
//   {path: "/cancellation_policy", noLoader: true},
//   {path: "/terms_of_service", noLoader: true},
//   {path: "/app", noLoader: true},
// ]
//
// const publicPages = [
//   // {path: "/classroom", noLoader: false},
//   // {path: "/tips/demo", noLoader: false},
//   // {path: "/practice/demo", noLoader: false},
// ];

function isIncluded(stringArr: string[], string: string) {
  let _isIncluded = false;
  for(let i = 0; i < stringArr.length; i++) {
    let str = stringArr[i];
    if(string.includes(str)) {
      _isIncluded = true;
      break;
    }
  }
  return _isIncluded;
}

function LoadingWrapper({show, setShow}) {

  useEffect(() => {
    if(show) {
      const timeOutId = setTimeout(() => {
        setShow(false);
      }, 2000)
      
      return () => {
        clearTimeout(timeOutId);
      }
    }
  }, [setShow, show])

  return (
    <div className="loading__wrapper" style={{display: show ? 'flex' : 'none'}} data-nosnippet="">
      <div className="loading__content fadeIn">
        <Image height={100} width={100}
               src={logoDark}
               alt="Pustack Logo"
               className="loader__logo"
        />
        <span id="loader" />
      </div>
    </div>
  )
}

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const isSmallScreen = useMediaQuery({ query: "(max-width: 500px)" });
  const [proSliderOpen, setProSliderOpen] = useState(false);
  const [show, setShow] = useState(false);
  // State for toggling bottom nav in mobile
  const [showMenuItem, setShowMenuItem] = useState(true);
  const [_isDarkMode, setIsDarkMode] = useState(false);
  // State for toggling the sidebar from navbar in smaller screens.
  const [mobileOpen, setMobileOpen] = useState(false);
  const [visibleNavbar, setVisibleNavbar] = useState(true);
  const authProps = useAuth();
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [_isSliderOpen, _setIsSliderOpen] = useState(false);
  const [_openGuestBottomDrawer, _setOpenGuestBottomDrawer] = useState(false);
  const [showLandingPage, setShowLandingPage] = useState(true);
  const initializedRef = useRef(false);
  const isNight = useIsNight();

  const isPublicPage = publicPages.find(c => router.pathname.includes(c.path));
  const isCommonPage = commonPages.find(c => (c.path === router.pathname));
  //@ts-ignore
  const getLayout = Component.getLayout || ((page) => page);

  // useEffect(() => {
  //   if(router.pathname !== '/') setShowLandingPage(false);
  // }, [router.pathname])

  const isDarkMode = useMemo(() => {
    if(showLandingPage || router.pathname.includes('/blaze')) return true;
    return _isDarkMode;
  }, [_isDarkMode, showLandingPage, router.pathname])

  useEffect(() => {
    const skipLandingPage = localStorage.getItem('skipLandingPage');
    setShowLandingPage(() => {
      initializedRef.current = true;
      return !(skipLandingPage === "true")
    });
  }, [])

  useEffect(() => {
    if(!initializedRef.current) return;
    if(!showLandingPage) localStorage.setItem('skipLandingPage', 'true');
    else localStorage.removeItem('skipLandingPage');
  }, [showLandingPage])

  useEffect(() => {
    if(authProps.user?.is_guest) {
      if(isNight) setIsDarkMode(true);
      else setIsDarkMode(false);
    }
  }, [authProps.user, isNight])

  const openGuestBottomDrawer = useMemo(() => {
    if (!isSmallScreen) return _openGuestBottomDrawer;
    const query = router.query.guest_bottom_drawer;
    if(!['doubt', 'pdf', 'classroom'].includes(query as string)) return false;
    return query;
  }, [_openGuestBottomDrawer, router.query.guest_bottom_drawer, isSmallScreen]);

  const setOpenGuestBottomDrawer = useCallback(async (isVisible) => {
    console.log('isVisible - ', isVisible, !isSmallScreen);
    if(!isSmallScreen) return _setOpenGuestBottomDrawer(isVisible);
    if(isVisible) {
      console.log('router.push - - ');
      await router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          guest_bottom_drawer: isVisible
        }
      });
      console.log('router.query - ', router);
      return;
    }
    return router.back();
    // const _query = {
    //   ...router.query,
    //   guest_bottom_drawer: undefined
    // };
    //
    // delete _query.guest_bottom_drawer;
    //
    // console.log('router.push - - ');
    // return router.replace({
    //   pathname: router.pathname,
    //   query: _query
    // });
  }, [isSmallScreen, router]);

  const isSliderOpen = useMemo(() => {
    if (!isSmallScreen) return _isSliderOpen;
    console.log('router.query.login_slider - ', router.query.login_slider);
    return router.query.login_slider === 'true';
  }, [_isSliderOpen, router.query.login_slider, isSmallScreen]);

  const setIsSliderOpen = useCallback(async (isVisible) => {
    console.log('isVisible - ', isVisible, !isSmallScreen);
    if(!isSmallScreen) return _setIsSliderOpen(isVisible);
    if(isVisible) {
      const query = {
        ...router.query,
        login_slider: true
      }
      delete query.guest_bottom_drawer;
      await router.push({
        pathname: router.pathname,
        query
      });
      console.log('router.query - ', router);
      return;
    }
    return router.back();
    // const _query = {
    //   ...router.query,
    //   login_slider: undefined
    // };
    //
    // delete _query.login_slider;
    //
    // console.log('router.push - - ');
    // return router.push({
    //   pathname: router.pathname,
    //   query: _query
    // });
  }, [isSmallScreen, router]);

  useEffect(() => {
    getCurrentVersionFn();
  }, []);

  const getCurrentVersionFn = async () => {
    const currentVersion = await getCurrentVersion();
    console.log('currentVersion - ', currentVersion);
    const version = localStorage.getItem('appVersion');
    if (version && version !== currentVersion.version) {
      caches.keys().then((names) => {
        for (let name of names) caches.delete(name);
      });
      console.log("not latest version");
      localStorage.setItem('appVersion', currentVersion.version);
      window.location.reload();
    } else {
      if(!version) localStorage.setItem('appVersion', currentVersion.version);
      console.log("latest version deployed");
    }
  };


  useEffect(() => {
    if(updateAvailable) window.location.reload();
    setUpdateAvailable(false);
  }, [router.pathname]);

  useEffect(() => {

    if (localStorage.getItem("pustack-dark-theme") === "true") {
      try {
        setIsDarkMode(true);
      } catch (error) {
        setIsDarkMode(false);
      }
    }

  }, []);

  const loadedRef = useRef(false);
  useEffect(() => {
    if(loadedRef.current) {
      // window.onbeforeunload = null;
      // return window.location.reload();
    }
    loadedRef.current = true;
  }, [isSmallScreen])

  useEffect(() => {
    if(isDarkMode) document.body.classList.add('dark');
    else document.body.classList.remove('dark');
  }, [isDarkMode]);

  useEffect(() => {
    window.onpopstate = function(e) {
      setProSliderOpen(false);
    }
  }, [])

  useEffect(() => {
    if(router.pathname === '/') {
      setShowMenuItem(true);
      setVisibleNavbar(true);
    }
  }, [router.pathname])

  useEffect(() => {
    if(authProps.isLoading) return setShow(true);
    // if(authProps?.user?.is_guest) setIsDarkMode(true);
  }, [authProps.user, authProps.isLoading])

  useEffect(() => {
    if(authProps.isLoading || isCommonPage) return;
    if(!authProps.user) {
      if(!isPublicPage && !router.query['step']) {
        console.log('router.push - - ');
        router.push({
          pathname: '/',
          query: router.query
        });
      }
    } else {
      if(isPublicPage) {
        console.log('router.push - - ');
        router.push({
          pathname: '/',
          query: router.query
        });
      }
    }
  }, [authProps.isLoading, isPublicPage, isCommonPage, authProps.user])

  if(authProps.isLoading) return <div className={authProps.isDarkMode ? 'dark' : ''} style={{
    width: '100vw',
    height: '100vh',
    backgroundColor: 'var(--color-base)'
  }} />

  let a = null;

  console.log('router - ', router.pathname);

  if(showLandingPage && router.pathname === '/') {
    return (
      <SnackbarContextProvider>
        {isSmallScreen
          ? <MobileLanding setShowLandingPage={setShowLandingPage} user={authProps.user}/>
          : <DesktopLanding setShowLandingPage={setShowLandingPage} user={authProps.user}/>}
        <SnackBar/>
      </SnackbarContextProvider>
    )
  }

  const render = (children) => (
    <IntroContextProvider setShow={setShow} show={show} setShowLandingPage={setShowLandingPage} showLandingPage={showLandingPage}>
      <ThemeContextProvider isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} showLandingPage={showLandingPage}>
        <SnackbarContextProvider>
          <LoadingWrapper show={show} setShow={setShow}/>
          {/*{!isPublicPage?.noLoader &&  <LoadingWrapper show={show} setShow={setShow}/>}*/}
          <SnackBar/>
          <UserContextProvider isSliderOpen={isSliderOpen} setIsSliderOpen={setIsSliderOpen} setOpenGuestBottomDrawer={setOpenGuestBottomDrawer} openGuestBottomDrawer={openGuestBottomDrawer} {...authProps}>
            <ChooseGrade />
            <PustackProContextProvider isOpen={proSliderOpen} setIsOpen={setProSliderOpen}>
              {children}
              <div id="context-menu" />
              <div id="modal" />
            </PustackProContextProvider>
            <Suspense fallback={<></>}>
              <OnBoardingFlow
                isOpen={isSliderOpen}
                setUser={authProps.setUser}
                user={authProps.user}
                handleClose={() => setIsSliderOpen(!isSliderOpen)}
              />
            </Suspense>
            <GuestBottomDrawer />
          </UserContextProvider>
        </SnackbarContextProvider>
      </ThemeContextProvider>
    </IntroContextProvider>
  );

  const layoutComponent = <Component
    {...pageProps}
    setVisibleNavbar={setVisibleNavbar}
    mobileOpen={mobileOpen}
    isLoggedIn={true}
    setMobileOpen={setMobileOpen}
    setShowMenuItem={setShowMenuItem}
    setUser={authProps.setUser}
    user={authProps.user}
    setProSliderOpen={setProSliderOpen}
    proSliderOpen={proSliderOpen}
    isSliderOpen={isSliderOpen}
    setIsSliderOpen={setIsSliderOpen}
  />;

  if(!authProps.user || isPublicPage) {
    a = (
      <Component {...pageProps} setUser={authProps.setUser} noAuth={true} isLoggedIn={false} setProSliderOpen={setProSliderOpen} proSliderOpen={proSliderOpen}  />
    )
  } else {
    a = (
      <ClassroomContextProvider>
        <TipContextProvider>
          <PracticeContextProvider>
            <SidebarContextProvider>
              <SubjectModalContextProvider>
                <LiveSessionContextProvider showMenuItem={showMenuItem} setShowMenuItem={setShowMenuItem}>
                  <ModalContextProvider>
                    <BlazeSessionContextProvider>
                      <CmsContextProvider>
                        <AppModal />
                        {!isIncluded(['cms', 'classroom', 'auth_classroom', 'tip', 'practice', 'about', 'contact', 'sitemap', 'terms_of_service', 'privacy_policy', 'cancellation_policy'], router.pathname) && visibleNavbar && (router.pathname === '/' ? !showLandingPage : true) && (
                          <NavbarContextProvider>
                            <Navbar hideHeader={router.pathname !== '/' && isIncluded([].concat(isSmallScreen ? ['blaze', 'classes'] : []), router.pathname)} user={authProps.user} setMobileOpen={setMobileOpen} showMenuItem={showMenuItem} />
                          </NavbarContextProvider>
                        )}
                        {
                          getLayout === 'LiveClasses' ? (
                            <LiveSessionLayout {...pageProps} user={authProps.user} setUser={authProps.setUser} isMobileOpen={mobileOpen} setIsMobileOpen={setMobileOpen} setProSliderOpen={setProSliderOpen} proSliderOpen={proSliderOpen} setShowMenuItem={setShowMenuItem}>
                              <Component isSliderOpen={isSliderOpen} setIsSliderOpen={setIsSliderOpen} setVisibleNavbar={setVisibleNavbar} {...pageProps} isLoggedIn={true} mobileOpen={mobileOpen} setShowMenuItem={setShowMenuItem} setUser={authProps.setUser} user={authProps.user} setProSliderOpen={setProSliderOpen} proSliderOpen={proSliderOpen} />
                            </LiveSessionLayout>
                          ) : getLayout === 'Blaze' ? (
                            <BlazeLayout {...pageProps} user={authProps.user} setUser={authProps.setUser} isMobileOpen={mobileOpen} setIsMobileOpen={setMobileOpen} setProSliderOpen={setProSliderOpen} proSliderOpen={proSliderOpen} setShowMenuItem={setShowMenuItem}>
                              <Component isSliderOpen={isSliderOpen} setIsSliderOpen={setIsSliderOpen} {...pageProps} setVisibleNavbar={setVisibleNavbar} isLoggedIn={true} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} setShowMenuItem={setShowMenuItem} setUser={authProps.setUser} user={authProps.user} setProSliderOpen={setProSliderOpen} proSliderOpen={proSliderOpen} />
                            </BlazeLayout>
                          ) : (
                            getLayout(layoutComponent, {
                              user: authProps.user,
                              setUser: authProps.setUser,
                              setProSliderOpen,
                              proSliderOpen,
                              setShowMenuItem,
                            })
                          )
                        }
                        {/*<Layout {...pageProps} user={authProps.user} setUser={authProps.setUser} setProSliderOpen={setProSliderOpen} proSliderOpen={proSliderOpen} setShowMenuItem={setShowMenuItem}>*/}
                        {/*  <Component {...pageProps} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} setUser={authProps.setUser} user={authProps.user} setProSliderOpen={setProSliderOpen} proSliderOpen={proSliderOpen} />*/}
                        {/*</Layout>*/}
                      </CmsContextProvider>
                      <BlazeCallNotification blazeCallAlert={authProps.blazeCallAlert} setBlazeCallAlert={authProps.setBlazeCallAlert}/>
                      <BookSessionContextProvider>
                        <BlazeBookingPopup/>
                      </BookSessionContextProvider>
                    </BlazeSessionContextProvider>
                    <PustackProSlider />
                    <PustackDoubtSlider />
                    <GetProWarning />
                    <FloatingPlayerContainer />
                    <AskNameModal />
                    <StoryModal />
                    {!authProps.user?.is_guest && <UserBadgesModal/>}
                    {/*<UserBadgesModal />*/}
                    {/*<PushUpdateNotification />*/}
                  </ModalContextProvider>
                </LiveSessionContextProvider>
              </SubjectModalContextProvider>
            </SidebarContextProvider>
          </PracticeContextProvider>
        </TipContextProvider>
      </ClassroomContextProvider>
    )
  }

  return render(a);
}

const EmptyLayout = ({children}) => <>{children}</>;
