import React, {useState, createContext, useMemo, useEffect, useCallback, useRef, useContext} from "react";
import {useRouter} from "next/router";
import { useMediaQuery } from "react-responsive";
import {fetchBunnyCDNCollections, fetchStories, getBunnyCDNConfig} from "../../database/story";
import {UserContext} from "../../context";

export const SubjectModalContext = createContext();

class StoryEvent {
  event;
  constructor() {
    this.event = new CustomEvent('storyEvent', {detail: {}});
  }

  addEventListener(eventName, callback) {
    // this.eventListeners.push(callback);
    window.addEventListener(eventName, callback);
  }

  removeEventListener(eventName, callback) {
    // this.eventListeners.push(callback);
    window.removeEventListener(eventName, callback);
  }

  dispatchEvent(eventName, data) {
    dispatchEvent(new CustomEvent(eventName, {detail: data}));
  }
}

export const SubjectModalContextProvider = (props) => {
  //------------------------------------ constants hooks

  const [_isOpen, _setIsOpen] = useState(false);
  const isTabletScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const [data, setData] = useState(null);
  const [subjectCode, setSubjectCode] = useState(null);
  const [subjectName, setSubjectName] = useState(null);
  const [tabData, setTabData] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [activeFlag, setActiveFlag] = useState(true);
  const router = useRouter()
  const [showStoryModal, setShowStoryModal] = useState(false);
  const [selectedStoryIndex, setSelectedStoryIndex] = useState(null);
  const [storyVideoIndices, setStoryVideoIndices] = useState({});
  const [stories, setStories] = useState(null);
  const storyEventRef = useRef(null);
  const [cursor, setCursor] = useState(0);
  const [subjectCollections, setSubjectCollections] = useState(null);
  const [bunnyCDNConfig, setBunnyCDNConfig] = useState(null);
  const [user] = useContext(UserContext).user;

  useEffect(() => {

    getBunnyCDNConfig()
      .then(config => {
        setBunnyCDNConfig(config);
      });
    fetchBunnyCDNCollections().then(collections => {
      setSubjectCollections(collections);
    })
  }, [])

  useEffect(() => {
    storyEventRef.current = new StoryEvent();
    // setStories(mockStories);
    fetchStories()
      .then(_stories => {
        setStories(_stories);
      })
  }, [user?.grade]);

  const isOpen = useMemo(() => {
    if(!isTabletScreen) return _isOpen;
    return router.query.subject_modal === 'true';
  }, [_isOpen, isTabletScreen, router])

  const setIsOpen = useCallback((isOpen) => {
    if(!isTabletScreen) return _setIsOpen(isOpen);

    if(isOpen) return router.push({
      pathname: router.pathname,
      query: {
        ...router.query,
        subject_modal: true
      }
    });
    const _query = {
      ...router.query,
      subject_modal: undefined
    };

    delete _query.subject_modal;

    return router.push({
      pathname: router.pathname,
      query: _query
    });
  }, [isTabletScreen, router])

  return (
    <SubjectModalContext.Provider
      value={{
        data: [data, setData],
        isOpen: [isOpen, setIsOpen],
        subjectCode: [subjectCode, setSubjectCode],
        subjectName: [subjectName, setSubjectName],
        tabData: [tabData, setTabData],
        activeTab: [activeTab, setActiveTab],
        activeFlag: [activeFlag, setActiveFlag],
        showStoryModal: [showStoryModal, setShowStoryModal],
        stories: [stories, setStories],
        selectedStoryIndex: [selectedStoryIndex, setSelectedStoryIndex],
        storyVideoIndices: [storyVideoIndices, setStoryVideoIndices],
        subjectCollections: [subjectCollections, setSubjectCollections],
        bunnyCDNConfig: [bunnyCDNConfig, setBunnyCDNConfig],
        storyCursor: [cursor, setCursor],
        storyEventRef: storyEventRef,
      }}
    >
      {props.children}
    </SubjectModalContext.Provider>
  );
};

export default SubjectModalContextProvider;
