import React, {useContext, useMemo, useState} from 'react';
import Lottie from "lottie-react-web";
import {Drawer} from '@material-ui/core';
// import astronautLottie from "../../public/assets/onboarding/astronaut.json";
import {Stars1, Stars2, Stars3, Stars4} from '../../public/assets';
import {UserContext} from '../../context'
import CloseIcon from '@material-ui/icons/Close';
import Image from 'next/image';
import AstronautLottie from '../AstronautLottie';

const slideCss = {
	position: "relative",
	minWidth: "100%",
	minHeight: "72px",
	height: '100%'
};

export default function GuestBottomDrawer() {
	const [_open, setOpen] = useContext(UserContext).openGuestBottomDrawer;
	const [, setBoardingFlowOpen] = useContext(UserContext).isSliderOpen;

	const open = useMemo(() => {
		if(['doubt', 'pdf', 'classroom'].includes(_open)) return _open;
		if(_open) return 'doubt';
		return false;
	}, [_open])

	const textData = {
		heading: {
			doubt: 'Connect with tutor',
			pdf: "Don't miss out!",
			classroom: "Don't miss out!"
		},
		description: {
			doubt: 'Join now to ask your doubt.',
			pdf: 'Join now to view this PDF!',
			classroom: 'Access lectures for free!'
		}
	}

	return (
		<Drawer
			variant="temporary"
			open={open}
			anchor={"bottom"}
			onClose={() => {
				setOpen(null);
				navigator && navigator.vibrate && navigator.vibrate(5);
			}}
			ModalProps={{ keepMounted: true, closeAfterTransition: true }}
			className="guest-bottom-drawer"
		>
			<div
				className="embla__slide"
				style={slideCss}
				onClick={() => {
					// embla.clickAllowed() && handleBannerClick(item);
				}}>
				<CloseIcon style={{
					width: '18px',
					height: '18px',
					position: 'absolute',
					top: '10px',
					right: '9px',
					zIndex: 2323123123,
					color: '#ffffff75'
				}} onClick={() => {
					console.log('setting open false');
					setOpen(false);
				}} />
				<div className="welcome-dialog" style={{height: '100%'}}>
					{/*<div className="stars__background">*/}
					{/*	<div className="stars"/>*/}
					{/*	<div className="twinkling"/>*/}
					{/*</div>*/}
					<Image className={"stars-bg stars-bg-1"} src={Stars1} alt={'stars'} />
					<Image className={"stars-bg stars-bg-2"} src={Stars2} alt={'stars'} />
					<Image className={"stars-bg stars-bg-3"} src={Stars3} alt={'stars'} />
					<Image className={"stars-bg stars-bg-4"} src={Stars4} alt={'stars'} />


					<div className="welcome-text">
						<div className="guest-user-banner-text">
							<div style={{
								color: 'white'
							}}>
								<h4 style={{
									fontWeight: 700,
									color: 'white',
									fontSize: '23px'
								}}>{textData.heading[open]}</h4>
								<p style={{
									color: '#ffffff9e',
									marginTop: '6px'
								}}>{textData.description[open]}</p>
							</div>
							<button className="call-to-action-welcome" style={{
								borderRadius: '500px',
								position: 'relative',
								marginTop: '33px',
								transform: 'none',
								left: 'unset',
								top: 'unset'
							}} onClick={() => {
								setBoardingFlowOpen('sign-in');
								// setOpen(false);
								// isSmallScreen ? router.push('/?step=login') : setOpenSignupModal(() => {
								//   setIsSliderOpen(true);
								//   return false;
								// });
							}}>Join Now
							</button>
						</div>
						<div className="astronaut">
							<AstronautLottie />
						</div>
					</div>
				</div>
			</div>
		</Drawer>
	)
}
