import React, {useContext, useState} from 'react';
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import {UserContext} from "../../context";
import ExitToApp from "@material-ui/icons/ExitToApp";

function Loader({className = '', style = {}}) {
	return <div className={"loader-37 flex-1 text-zinc-900 dark:text-white " + (className ? className : '')} style={style} />
}
export default function AskNameModal() {
	const [messageText, setMessageText] = useState('');
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useContext(UserContext).user;
	const [openModal, setOpenNameModal] = useContext(UserContext).openNameModal;

	const handleChangeName = async () => {
		if(!user?.uid || messageText.trim().length === 0) return;
		setLoading(true);
		await require('../../firebase-config').db.collection('users')
			.doc(user.uid)
			.set({
				name: messageText.trim()
			}, {merge: true})
		setOpenNameModal(false);
		setLoading(false);
		setMessageText('');
	}

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			// className="w-80 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl p-4"
			open={openModal}
			onClose={() => {
				setOpenNameModal(false)
			}}
			closeAfterTransition
			disableBackdropClick
			disableEscapeKeyDown
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<div className="ask-name-modal">
				<div className={"flex justify-between items-center"}>
					<p className="text-black dark:text-white font-medium font-sans">My name is</p>
					<button className="cursor-pointer text-red-700 text-sm" onClick={() => {
						require('../../firebase-config').auth.signOut();
						window.location.reload();
					}}><ExitToApp /></button>
				</div>
				<hr className="h-px mt-3 mb-4 bg-gray-200 border-0 dark:bg-gray-700" />
				<input type="text" value={messageText} onChange={e => setMessageText(e.target.value)} id="first_name"
				       className="ask-name-input"
				       placeholder="Enter Name" required />
				<button disabled={messageText.trim().length === 0} className={"ask-name-button grant-button "} onClick={handleChangeName}>
					{loading ? <Loader/> : (
						<>
							<span className="font-medium text-white text-md">Submit</span>
						</>
					)}
				</button>
			</div>
		</Modal>
	)
}
