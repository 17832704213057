import React, {useState, createContext, useRef, FC, Dispatch} from 'react';
import {
  AuthenticationJourney, BlazeSessionCallJourney,
  BlazeSessionCreationJourney,
  BlazeSessionEndJourney,
  ProSubscriptionJourney, ScholarshipJourney
} from '../../helpers/journeys';

type IntroContext = {
  master: [string, Dispatch<string>];
  anchor: [any, Dispatch<any>];
  openWelcome: [boolean, Dispatch<boolean>];
  openFreeTrial: [boolean, Dispatch<boolean>];
  availableGrades: [any[], Dispatch<any[]>];
  trialType: [any, Dispatch<any>];
  showLoader: [boolean, Dispatch<boolean>];
  signInSliderOpen: [boolean, Dispatch<boolean>];
  openSignupModal: [boolean, Dispatch<boolean>];
  showGradeChange: [boolean, Dispatch<boolean>];
  showLandingPage: [boolean, Dispatch<boolean>];
  authJourneyRef: React.MutableRefObject<AuthenticationJourney>
  proSubscriptionJourneyRef: React.MutableRefObject<ProSubscriptionJourney>
  blazeSessionCreationJourneyRef: React.MutableRefObject<BlazeSessionCreationJourney>
  blazeSessionEndJourneyRef: React.MutableRefObject<BlazeSessionEndJourney>
  blazeSessionCallJourneyRef: React.MutableRefObject<BlazeSessionCallJourney>
  scholarshipJourneyRef: React.MutableRefObject<ScholarshipJourney>
};

export const IntroContext = createContext<IntroContext>(
  {} as IntroContext
);

// @ts-ignore
export const IntroContextProvider: FC = ({show, setShow, showLandingPage, setShowLandingPage, ...props}) => {
  //------------------------------------ constants hooks

  const [master, setMaster] = useState("home");
  const [anchor, setAnchor] = useState(null);
  const [openWelcome, setOpenWelcome] = useState(true);
  const [openFreeTrial, setOpenFreeTrial] = useState(false);
  const [trialType, setTrialType] = useState(null);
  const [availableGrades, setAvailableGrades] = useState([]);
  const [signInSliderOpen, setSignInSliderOpen] = useState(false);
  const [showGradeChange, setShowGradeChange] = useState(true);
  const [openSignupModal, setOpenSignupModal] = useState(false);
  const authJourneyRef = useRef(new AuthenticationJourney());
  const proSubscriptionJourneyRef = useRef(new ProSubscriptionJourney());
  const blazeSessionCreationJourneyRef = useRef(new BlazeSessionCreationJourney());
  const blazeSessionEndJourneyRef = useRef(new BlazeSessionEndJourney());
  const blazeSessionCallJourneyRef = useRef(new BlazeSessionCallJourney());
  const scholarshipJourneyRef = useRef(new ScholarshipJourney());

  return (
    <IntroContext.Provider
      value={{
        master: [master, setMaster],
        anchor: [anchor, setAnchor],
        openWelcome: [openWelcome, setOpenWelcome],
        openFreeTrial: [openFreeTrial, setOpenFreeTrial],
        availableGrades: [availableGrades, setAvailableGrades],
        trialType: [trialType, setTrialType],
        showLoader: [show, setShow],
        signInSliderOpen: [signInSliderOpen, setSignInSliderOpen],
        openSignupModal: [openSignupModal, setOpenSignupModal],
        showGradeChange: [showGradeChange, setShowGradeChange],
        showLandingPage: [showLandingPage, setShowLandingPage],
        authJourneyRef,
        proSubscriptionJourneyRef,
        blazeSessionCreationJourneyRef,
        blazeSessionEndJourneyRef,
        blazeSessionCallJourneyRef,
        scholarshipJourneyRef
      }}
    >
      {/*// @ts-ignore*/}
      {props.children}
    </IntroContext.Provider>
  );
};

export default IntroContextProvider;
