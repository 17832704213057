// import "firebase/firestore";
// import {require('../../firebase-config').db, functions, require('../../firebase-config').storage} from "../../firebase_config";
import {castIndianTime, transformDateTimeObject} from "../../helpers/getIndianTime";
import {formatCallDuration} from '../../helpers/functions/formatCallDuration';
import {v4 as uuid} from "uuid";
import {formatDateDoc} from "../livesessions/sessions";
import Axios from '../../helpers/pustack-axios';
import {defaultPic} from "../../public/assets";

export const updateCallDocument = async ({
                                           sessionId,
                                           meetingId,
                                           status,
                                           duration,
                                           reason = null,
                                           platform,
                                           studentEntryId,
                                         }) => {
  const indianTime = await castIndianTime(true);
  const dateTimeObject = transformDateTimeObject(indianTime);
  if (status === "completed")
    await require('../../firebase-config').db
      .collection( "blaze_dev")
      .doc("collections")
      .collection("blaze_sessions")
      .doc(sessionId)
      .collection("chats")
      .doc(meetingId)
      .set(
        {
          message_type: "call_event",
          event_description: `Call Duration • ${formatCallDuration(duration)}`,
          event_type: status,
          sent_on: indianTime.serverISOString,
        },
        { merge: true }
      )
      .then(() => true)
      .catch(() => false);

  console.log('setting call_start_ts from here 40 - ');

  let dataToSet = {
    // active_call_duration: duration,
    // billed_amount: duration * 10,
    status: status,
    student_platform: platform,
    sent_on: indianTime.serverISOString,
    reason_for_call_end: reason,
    student_entry_id: studentEntryId ? studentEntryId.toString() : null,
    // call_start_ts: indianTime.serverISOString,
  };

  if (status === "completed") {
    dataToSet = {
      // active_call_duration: duration,
      // billed_amount: duration * 10,
      status: status,
      student_platform: platform,
      reason_for_call_end: reason,
    };
  }

  return await require('../../firebase-config').db
    .collection("blaze_dev")
    .doc("collections")
    .collection("blaze_sessions")
    .doc(sessionId)
    .collection("calls")
    .doc(meetingId)
    .set(dataToSet, { merge: true })
    .then(() => true)
    .catch(() => false);
};

export const rejectCallStatus = async ({ sessionId, meetingId }) => {
  const indianTime = await castIndianTime(true);
  const dateTimeObject = transformDateTimeObject(indianTime);
  require('../../firebase-config').db.collection("blaze_dev")
    .doc("collections")
    .collection("blaze_sessions")
    .doc(sessionId)
    .collection("calls")
    .doc(meetingId)
    .set(
      {
        status: "rejected_by_student",
        student_platform: "web",
        sent_on: indianTime.serverISOString,
      },
      { merge: true }
    )
    .catch((err) => err);
};

export const updateStudentEngagement = async ({
                                          studentId,
                                          activeCallId,
                                          activeSessionId,
                                          isEngaged,
                                        }) => {
  const ist = await castIndianTime(true);
  require('../../firebase-config').db.collection("blaze_dev")
    .doc("collections")
    .collection("students")
    .doc(studentId)
    .set(
      {
        call_state: activeCallId ? {
          call_id: activeCallId,
          session_id: activeSessionId,
          is_engaged: isEngaged,
          expires_on: ist.add(30000).toISOString()
        } : null,
      },
      { merge: true }
    );
};

export const startHandoverStudent = async ({
                                             sessionId,
                                             meetingId,
                                             isSmallScreen,

                                           }) => {
  return await require('../../firebase-config').db
    .collection("blaze_dev")
    .doc("collections")
    .collection("blaze_sessions")
    .doc(sessionId)
    .collection("calls")
    .doc(meetingId)
    .set(
      {
        status: "handover_student",
        student_platform: isSmallScreen ? "mweb" : "web",
      },
      { merge: true }
    )
    .then(() => true)
    .catch((err) => {
      console.log(err);
      return false;
    });
};


export function getBlazeSessions({
                                   user_id,
                                   type,
                                   timestamp,
                                   limit,
                                   callback,
                                 }) {
  return require('../../firebase-config').db.collection("blaze_dev")
    .doc("collections")
    .collection("blaze_sessions")
    .where("student_id", "==", user_id)
    .where("session_status", "==", type)
    .orderBy(timestamp, "desc")
    .limit(limit)
    .onSnapshot((snapshot) => {
      let sessions = [];

      if (snapshot.docs.length === 0) {
      } else {
        for (let i = 0; i < snapshot.docs.length; i++) {
          let _currentDocument = snapshot.docs[i].data();

          sessions.push({
            ..._currentDocument,
            reference: snapshot.docs[i].ref,
          });
        }
      }

      callback(sessions);
    });
}

export const getBlazeCallHistory = ({ reference, callback }) => {
  if (!reference) return;

  return reference
    .collection("calls")
    .orderBy("sent_on", "asc")
    .onSnapshot((e) => {
      let _data = [];

      for (let i = 0; i < e.docs.length; i++) {
        let _current = e.docs[i].data();

        let _currentCall = null;

        _currentCall = {
          type: "call_detail",
          status: _current?.status,
          timestamp: _current?.sent_on,
          duration: _current?.active_call_duration,
          student_platform: _current?.student_platform,
          instructor_platform: _current?.instructor_platform,
        };

        _data.push(_currentCall);
      }
      callback(_data);
    });
};

export function getLatestBlazeReservationChats({
                                                 reference,
                                                 user_id,
                                                 doc,
                                                 callback,
                                               }) {
  if (typeof reference !== "undefined" && doc) {
    return reference
      .collection("chats")
      .orderBy("sent_on", "asc")
      .where("sent_on", ">", doc?.timestamp)
      .onSnapshot((e) => {

        let docs = e.docs;
        let modified = false;

        if(e.docChanges().length > 0) {
          docs = e.docChanges().map(c => c.doc);
          if(e.docChanges()[0].type === 'modified') {
            modified = true;
          }
        }

        let _data = [];

        for (let i = 0; i < docs.length; i++) {
          let _current = docs[i].data();

          let _currentChat = null;

          if (_current?.message_type === "text") {
            _currentChat = {
              type: _current?.message_type,
              attachment: _current?.attachment,
              timestamp: _current?.sent_on,
              message: _current?.message,
              isByUser: user_id === _current?.sender_id,
              sender_pic: _current?.sender_profile_picture,
              receiver_id: _current?.receiver_id,
            };
          }
          if (_current?.message_type === "call_event") {
            _currentChat = {
              type: _current?.message_type,
              event_type: _current?.event_type,
              timestamp: _current?.sent_on,
              message: _current?.event_description,
            };
          }
          if (_current?.message_type === "attachment") {
            if (_current?.attachment?.attachment_type === "image") {
              _currentChat = {
                type: "image",
                attachment: {
                  url: _current?.attachment?.attachment_url,
                  name: _current?.attachment?.attachment_name,
                },
                timestamp: _current?.sent_on,
                isByUser: user_id === _current?.sender_id,
                message: _current?.message,
                sender_pic: _current?.sender_profile_picture,
                receiver_id: _current?.receiver_id,
              };
            } else if (_current?.attachment?.attachment_type === "pdf") {
              _currentChat = {
                type: "document",
                attachment: {
                  url: _current?.attachment?.attachment_url,
                  name: _current?.attachment?.attachment_name,
                },
                timestamp: _current?.sent_on,
                isByUser: user_id === _current?.sender_id,
                message: _current?.message,
                sender_pic: _current?.sender_profile_picture,
                receiver_id: _current?.receiver_id,
              };
            } else if (_current?.attachment?.attachment_type === "lecture") {
              _currentChat = {
                type: "lecture",
                attachment: {
                  url: _current?.attachment?.attachment_url,
                  name: _current?.attachment?.attachment_name,
                  _meta: _current?.attachment?._meta
                },
                timestamp: _current?.sent_on,
                isByUser: user_id === _current?.sender_id,
                message: _current?.message,
                sender_pic: _current?.sender_profile_picture,
                receiver_id: _current?.receiver_id,
              };
            } else if (_current?.attachment?.attachment_type === "chapter") {
              _currentChat = {
                type: "chapter",
                attachment: {
                  url: _current?.attachment?.attachment_url,
                  name: _current?.attachment?.attachment_name,
                  _meta: _current?.attachment?._meta
                },
                timestamp: _current?.sent_on,
                isByUser: user_id === _current?.sender_id,
                message: _current?.message,
                sender_pic: _current?.sender_profile_picture,
                receiver_id: _current?.receiver_id,
              };
            }
          }

          _data.push(_currentChat);
        }

        console.log('chats - 306: ', _data);
        callback(_data, modified);
      });
  } else callback([]);
  return () => {};
}

export async function getBlazeReservationChats({ reference, user_id }) {
  if (typeof reference !== "undefined") {
    return await reference
      .collection("chats")
      .orderBy("sent_on", "desc")
      .limit(25)
      .get()
      .then((e) => {
        let _data = [];

        for (let i = 0; i < e.docs.length; i++) {
          let _current = e.docs[i].data();

          let _currentChat = null;

          if (_current?.message_type === "text") {
            _currentChat = {
              type: _current?.message_type,
              attachment: _current?.attachment,
              timestamp: _current?.sent_on,
              message: _current?.message,
              isByUser: user_id === _current?.sender_id,
              sender_pic: _current?.sender_profile_picture,
              id: _current?.chat_id
            };
          }
          if (_current?.message_type === "call_event") {
            _currentChat = {
              type: _current?.message_type,
              event_type: _current?.event_type,
              timestamp: _current?.sent_on,
              message: _current?.event_description,
              id: _current?.chat_id
            };
          }
          if (_current?.message_type === "attachment") {
            if (_current?.attachment?.attachment_type === "image") {
              _currentChat = {
                type: "image",
                attachment: {
                  url: _current?.attachment?.attachment_url,
                  name: _current?.attachment?.attachment_name,
                },
                timestamp: _current?.sent_on,
                isByUser: user_id === _current?.sender_id,
                message: _current?.message,
                sender_pic: _current?.sender_profile_picture,
                id: _current?.chat_id
              };
            } else if (_current?.attachment?.attachment_type === "pdf") {
              _currentChat = {
                type: "document",
                attachment: {
                  url: _current?.attachment?.attachment_url,
                  name: _current?.attachment?.attachment_name,
                },
                timestamp: _current?.sent_on,
                isByUser: user_id === _current?.sender_id,
                message: _current?.message,
                sender_pic: _current?.sender_profile_picture,
                id: _current?.chat_id
              };
            } else if (_current?.attachment?.attachment_type === "lecture") {
              _currentChat = {
                type: "lecture",
                attachment: {
                  url: _current?.attachment?.attachment_url,
                  name: _current?.attachment?.attachment_name,
                  _meta: _current?.attachment?._meta
                },
                timestamp: _current?.sent_on,
                isByUser: user_id === _current?.sender_id,
                message: _current?.message,
                sender_pic: _current?.sender_profile_picture,
                receiver_id: _current?.receiver_id,
              };
            } else if (_current?.attachment?.attachment_type === "chapter") {
              _currentChat = {
                type: "chapter",
                attachment: {
                  url: _current?.attachment?.attachment_url,
                  name: _current?.attachment?.attachment_name,
                  _meta: _current?.attachment?._meta
                },
                timestamp: _current?.sent_on,
                isByUser: user_id === _current?.sender_id,
                message: _current?.message,
                sender_pic: _current?.sender_profile_picture,
                receiver_id: _current?.receiver_id,
              };
            }
          }

          _data.push(_currentChat);
        }
        return _data.reverse();
      })
      .catch((err) => console.log(err));
  } else return [];
}

export async function getMoreBlazeReservationChats({
                                                     reference,
                                                     user_id,
                                                     doc,
                                                   }) {
  if (typeof reference !== "undefined") {
    return await reference
      .collection("chats")
      .orderBy("sent_on", "desc")
      .where("sent_on", "<", doc?.timestamp)
      .limit(10)
      .get()
      .then((e) => {
        let _data = [];

        for (let i = 0; i < e.docs.length; i++) {
          let _current = e.docs[i].data();

          let _currentChat = null;

          if (_current?.message_type === "text") {
            _currentChat = {
              type: _current?.message_type,
              attachment: _current?.attachment,
              timestamp: _current?.sent_on,
              message: _current?.message,
              isByUser: user_id === _current?.sender_id,
              sender_pic: _current?.sender_profile_picture,
              id: _current?.chat_id
            };
          }
          if (_current?.message_type === "call_event") {
            _currentChat = {
              type: _current?.message_type,
              event_type: _current?.event_type,
              timestamp: _current?.sent_on,
              message: _current?.event_description,
              id: _current?.chat_id
            };
          }
          if (_current?.message_type === "attachment") {
            if (_current?.attachment?.attachment_type === "image") {
              _currentChat = {
                type: "image",
                attachment: {
                  url: _current?.attachment?.attachment_url,
                  name: _current?.attachment?.attachment_name,
                },
                timestamp: _current?.sent_on,
                isByUser: user_id === _current?.sender_id,
                message: _current?.message,
                sender_pic: _current?.sender_profile_picture,
                id: _current?.chat_id
              };
            } else if (_current?.attachment?.attachment_type === "pdf") {
              _currentChat = {
                type: "document",
                attachment: {
                  url: _current?.attachment?.attachment_url,
                  name: _current?.attachment?.attachment_name,
                },
                timestamp: _current?.sent_on,
                isByUser: user_id === _current?.sender_id,
                message: _current?.message,
                sender_pic: _current?.sender_profile_picture,
                id: _current?.chat_id
              };
            }
          }

          _data.push(_currentChat);
        }
        return _data.reverse();
      })
      .catch((err) => console.log(err));
  } else return null;
}

const uploadPdf = async (file, sessionId, userId) => {
  var path = `blaze_sessions/${sessionId}/from-${userId}/${uuid()}.png`;
  var _url = await require('../../firebase-config').storage
    .ref()
    .child(path)
    .put(await fetch(file.url).then((r) => r.blob()))
    .then(async (snapshot) => {
      return snapshot.ref.getDownloadURL().then((url) => url);
    });

  return _url;
};

export async function sendBlazeChat({
                                      user,
                                      text,
                                      reference,
                                      images = [],
                                      pdfs = [],
                                      session_id,
                                      receiver_id,
                                      student_grade,
                                      session_topic,
                                      type,
                                      disableNotification
                                    }) {
  let _imageUrl, _pdfUrl;

  let attachment;

  if (images.length > 0) {
    _imageUrl = await uploadImage(images[0], session_id, user?.uid).then(
      (url) => url
    );

    attachment = {
      attachment_name: images[0].name,
      attachment_type: "image",
      attachment_url: _imageUrl,
    };
  }
  if (pdfs.length > 0) {
    _pdfUrl = await uploadPdf(pdfs[0], session_id, user?.uid).then(
      (url) => url
    );

    attachment = {
      attachment_name: pdfs[0].name,
      attachment_type: "pdf",
      attachment_url: _pdfUrl,
    };
  }

  let indianTime = await castIndianTime(true);
  const dateTimeObject = transformDateTimeObject(indianTime);
  const ref = reference.collection("chats").doc();

  const chatObject = {
    message: text,
    message_type: type,
    attachment: images.length > 0 || pdfs.length > 0 ? attachment : null,
    sender_id: user?.uid,
    id: ref.id,
    sender_name: user?.name,
    sender_profile_picture: user?.profile_url ?? defaultPic,
    session_topic,
    receiver_id: receiver_id,
    sent_on: indianTime.serverISOString
  };

  const deliverBlazeMessageNotification = require('../../firebase-config').functions.httpsCallable(
    "deliverBlazeMessageNotification"
  );


  chatObject.chat_id = ref.id;

  await ref.set(chatObject);
  await reference
    .set({ last_message_ts: indianTime.serverISOString }, { merge: true })
    .catch((err) => console.log(err));

  if(disableNotification) return;

  if (user?.is_external_instructor) {
    if (receiver_id) {
      deliverBlazeMessageNotification({
        receiver_id: receiver_id,
        message: chatObject,
        session_id: session_id,
        session_topic,
        is_receiver_instructor: true
      }).catch((err) => console.log(err));

      reference
        .collection("chat_meta")
        .doc(receiver_id)
        .set(
          {
            unread_count: require('../../firebase-config').firebase.firestore.FieldValue.increment(1),
            last_message: chatObject,
          },
          { merge: true }
        );

      // require('../../firebase-config').db.collection("user_notifications")
      //   .doc(student_grade)
      //   .collection("user_notifications")
      //   .doc(receiver_id)
      //   .set(
      //     {
      //       unread_blaze_message_count:
      //         require('../../firebase-config').firebase.firestore.FieldValue.increment(1),
      //     },
      //     { merge: true }
      //   );
    }
  } else {
    if (receiver_id) {
      deliverBlazeMessageNotification({
        receiver_id: receiver_id,
        message: chatObject,
        session_id: session_id,
        session_topic,
        is_receiver_instructor: true
      }).catch((err) => console.log(err));

      reference
        .collection("chat_meta")
        .doc(receiver_id)
        .set(
          {
            unread_count: require('../../firebase-config').firebase.firestore.FieldValue.increment(1),
            last_message: chatObject,
          },
          { merge: true }
        );

      // require('../../firebase-config').db.collection("user_notifications")
      //   .doc("instructor")
      //   .collection("user_notifications")
      //   .doc(receiver_id)
      //   .set(
      //     {
      //       unread_blaze_message_count:
      //         require('../../firebase-config').firebase.firestore.FieldValue.increment(1),
      //     },
      //     { merge: true }
      //   );
    }
  }
  await require('../../firebase-config').rdb.ref('/users/' + receiver_id + '/pustack_tutor_app/unread_blaze_message_count/' + session_id).set(require('../../firebase-config').firebase.database.ServerValue.increment(1));
}

export const blazeDecreaseMessageCount = (
  reference,
  count,
  grade,
  userId,
  isExternalInstructor,
  receiverId,
  sessionId
) => {
  console.log('receiverId, sessionId ', receiverId, sessionId);
  // const decrement = require('../../firebase-config').firebase.firestore.FieldValue.increment(-1 * count);

  // let totalMessagesUnreadCountRef = require('../../firebase-config').db
  //   .collection("user_notifications")
  //   .doc(grade)
  //   .collection("user_notifications")
  //   .doc(userId);
  //
  // console.log('totalMessagesUnreadCountRef.path - ', totalMessagesUnreadCountRef.path);

  if (reference !== null) {

    let totalMessagesUnreadCountRef = require('../../firebase-config').rdb
      .ref('/users/' + userId + '/pustack_app/unread_blaze_message_count/' + sessionId);

    totalMessagesUnreadCountRef.set(0);

    reference.collection("chat_meta").doc(userId).set(
      {
        unread_count: 0,
      },
      { merge: true }
    );
  }

  if('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      const reg = registrations.find(c => c.active.scriptURL === 'https://pustack.com/firebase-messaging-sw.js' || c.active.scriptURL === 'http://localhost:3000/firebase-messaging-sw.js');
      reg.getNotifications({tag: sessionId}).then(notifications => {
        notifications.forEach(c => c.close());
      })
    })
  }


  if(receiverId && sessionId) {
    const dismissBlazeMessageNotification = require('../../firebase-config').asiaFunctions.httpsCallable(
      "dismissBlazeMessageNotification"
    );

    dismissBlazeMessageNotification({
      uid: userId,
      is_receiver_instructor: false,
      session_id: sessionId
    })
  }
};

export const blazeInstructorMetaDetails = async (instructorId) => {
  if (typeof instructorId !== "undefined") {
    const metaRef = require('../../firebase-config').db
      .collection("users")
      .doc(instructorId)
      .collection("meta")
      .doc(instructorId);

    return await metaRef.get().then((data) => data.data());
  }

  return null;
};

export const blazeUnreadMesagesNotification = ({
                                                 userId,
                                                 grade,
                                                 isExternal,
                                                 callback,
                                               }) => {
  return require('../../firebase-config').db.collection("user_notifications")
    .doc(isExternal ? "instructor" : grade)
    .collection("user_notifications")
    .doc(userId)
    .onSnapshot((snapshot) => {
      if (snapshot.exists) {
        let _data = snapshot.data();
        if (_data["unread_blaze_message_count"] > 0) {
          return callback(true);
        }

        callback(false);
      }
    });
};

export const blazeReservationMeta = async ({ grade }) => {
  return await require('../../firebase-config').db
    .collection(`blaze_dev/collections/cms_hierarchy`)
    .doc(grade)
    .get()
    .then((doc) => {
      const _data = doc.data();

      return _data["subject_chapter_map"];
    })
    .catch((_) => {});
};

export const subjectColorsMeta = async ({ grade }) => {
  return await require('../../firebase-config').db
    .collection(`blaze_dev/collections/cms_hierarchy`)
    .doc(grade)
    .get()
    .then((doc) => {
      const _data = doc.data();

      return _data["subject_color_map"];
    })
    .catch((_) => {});
};

export const getStudentActiveSessionDetails = ({ studentId, callback }) => {
  return require('../../firebase-config').db.collection("blaze_dev")
    .doc("collections")
    .collection("students")
    .doc(studentId)
    .onSnapshot((response) => {
      if (response.exists) {
        callback(response.data());
      } else {
        callback(null);
      }
    });
};

export const getRtmToken = async ({ sessionId }) => {
  return await require('../../firebase-config').db
    .collection("blaze_dev")
    .doc("collections")
    .collection("blaze_sessions")
    .doc(sessionId)
    .get()
    .then((response) => {
      if (response.exists) {
        return response.data()?.rtm_token;
      } else return null;
    })
    .catch(() => null);
};

export const endSession = async ({
                                   sessionId,
                                   completedTs,
                                   studentId,
                                   instructorId,
                                   instructorName,
                                   instructorProfilePic,
                                   topic,
                                   skill,
                                   ratingTs,
                                   rating = null,
                                   is_out_reach
                                 }) => {

  if (completedTs) {
    if (instructorId && !ratingTs && !is_out_reach) {
      await require('../../firebase-config').db
        .collection("blaze_dev")
        .doc("collections")
        .collection("students")
        .doc(studentId)
        .set(
          {
            pending_rating_list: require('../../firebase-config').firebase.firestore.FieldValue.arrayUnion({
              instructor_id: instructorId,
              instructor_name: instructorName,
              instructor_profile_pic: instructorProfilePic,
              session_id: sessionId,
              topic: topic,
              skill: skill,
              rating_ts: null,
              session_end_ts: completedTs
            }),
          },
          { merge: true }
        );
    }

    if (rating) {
      Axios.post(
        "https://us-central1-avian-display-193502.cloudfunctions.net/updateSkillRating",
        {
          rating: rating,
          skill: skill,
          instructorId: instructorId,
          sessionId: sessionId,
          studentId: studentId,
          context: {
            auth: studentId,
          },
        }
      )
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    }

    return await require('../../firebase-config').db
      .collection("blaze_dev")
      .doc("collections")
      .collection("blaze_sessions")
      .doc(sessionId)
      .set(
        {
          session_status: "completed",
          completed_ts: completedTs,
          rating: rating,
          rating_ts: ratingTs,
        },
        { merge: true }
      )
      .then(() => true)
      .catch(() => false);
  } else {
    if (rating) {
      Axios.post(
        "https://us-central1-avian-display-193502.cloudfunctions.net/updateSkillRating",
        {
          rating: rating,
          skill: skill,
          instructorId: instructorId,
          sessionId: sessionId,
          studentId: studentId,
          context: {
            auth: studentId,
          },
        }
      )
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    }
    return await require('../../firebase-config').db
      .collection("blaze_dev")
      .doc("collections")
      .collection("blaze_sessions")
      .doc(sessionId)
      .set(
        {
          rating: rating,
          rating_ts: ratingTs,
        },
        { merge: true }
      )
      .then(() => true)
      .catch(() => false);
  }
};

export const pendingSessionList = async ({ studentId }) => {
  return await require('../../firebase-config').db
    .collection("blaze_dev")
    .doc("collections")
    .collection("students")
    .doc(studentId)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data().pending_rating_list || [];
      }
    })
    .catch(() => []);
};

export const updatePendingRatingList = ({ session, completedTs }) => {
  require('../../firebase-config').db.collection("blaze_dev")
    .doc("collections")
    .collection("students")
    .doc(session?.student_id)
    .set(
      {
        pending_rating_list: require('../../firebase-config').firebase.firestore.FieldValue.arrayUnion({
          completed_ts: completedTs,
          instructor_id: session?.instructor_id,
          instructor_name: session?.instructor_name,
          session_id: session?.id,
          topic: session?.topic,
          session_end_ts: completedTs
        }),
        session_count: require('../../firebase-config').firebase.firestore.FieldValue.increment(1),
      },
      { merge: true }
    );
};

export const updateInstructorRating = ({ instructorId, topic, rating }) => {
  require('../../firebase-config').db.collection("blaze_dev")
    .doc("collections")
    .collection("instructors")
    .doc(instructorId)
    .collection("stats")
    .doc("topic_wise_rating")
    .get()
    .then((doc) => {
      let topicArray = [rating];
      if (doc.exists) {
        let ratingMap = doc.data().rating_map;
        if (ratingMap) {
          topicArray = ratingMap[topic];

          if (topicArray) topicArray.push(rating);
          else topicArray = [rating];
        }
      }

      require('../../firebase-config').db.collection("blaze_dev")
        .doc("collections")
        .collection("instructors")
        .doc(instructorId)
        .collection("stats")
        .doc("topic_wise_rating")
        .set(
          {
            rating_map: {
              [topic]: topicArray,
            },
          },
          { merge: true }
        );
    })
    .catch((err) => console.log(err));
};

export const getInstructorRatings = async ({ instructorId }) => {
  if (instructorId) {
    return await require('../../firebase-config').db
      .collection("blaze_dev")
      .doc("collections")
      .collection("instructors")
      .doc(instructorId)
      .collection("stats")
      .doc("skill_wise_rating")
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data().average_rating;
        }
      });
  }
};

export const listenToUnreadMessages = ({ sessionId, userId, callback }) => {
  if (sessionId) {
    return require('../../firebase-config').db.collection("blaze_dev")
      .doc("collections")
      .collection("blaze_sessions")
      .doc(sessionId)
      .collection("chat_meta")
      .doc(userId)
      .onSnapshot((doc) => {
        if (doc.exists) {
          callback(doc.data().unread_count);
        }
      });
  }
  return () => {};
};

export const listenToOutstandingSession = ({ sessionId, callback }) => {
  if (sessionId) {
    return require('../../firebase-config').db
      .collection("blaze_dev")
      .doc("collections")
      .collection("blaze_sessions")
      .doc(sessionId)

      .onSnapshot((doc) => {
        if (doc.exists) {
          callback(doc.data(), () => {});
        }
      });
  }
  return () => {};
};

export const isStudentEngaged = async ({ studentId }) => {
  return await require('../../firebase-config').db
    .collection("blaze_dev")
    .doc("collections")
    .collection("students")
    .doc(studentId)
    .get()
    .then(async (doc) => {
      if (doc.exists) {
        const ist = await castIndianTime(true);
        const isEngaged = doc.data().is_engaged ?? doc.data().call_state?.is_engaged;
        const expiresOn = doc.data().call_state?.expires_on;
        const sessionId = doc.data().active_call_session_id ?? doc.data().call_state?.session_id;
        const meetingId = doc.data().active_call_id ?? doc.data().call_state?.call_id;

        const isStudentEngaged = isEngaged && ist.serverISOString > expiresOn;

        if (isStudentEngaged) {
          return await require('../../firebase-config').db
            .collection("blaze_dev")
            .doc("collections")
            .collection("blaze_sessions")
            .doc(sessionId)
            .collection("calls")
            .doc(meetingId)
            .get()
            .then((doc1) => {
              if (doc1.exists) {
                let status = doc1.data().status;

                if (status === "engaged") {
                  return true;
                }
              }
              return false;
            })
            .catch(() => true);
        }
      } else return false;
    })
    .catch(() => true);
};

export const getReceiverUnreadCount = ({ sessionId, receiverId, callback }) => {
  require('../../firebase-config').rdb.ref('/users/' + receiverId + '/pustack_tutor_app/unread_blaze_message_count').on('value', (snapshot) => {
    console.log('snapshot.val() = ', snapshot);
    const values = snapshot.val() ?? {};
    callback(values[sessionId] ?? 0)
  })
  // return require('../../firebase-config').db.collection("blaze_dev")
  //   .doc("collections")
  //   .collection("blaze_sessions")
  //   .doc(sessionId)
  //   .collection("chat_meta")
  //   .doc(receiverId)
  //   .onSnapshot((doc) => {
  //     if (doc.exists) {
  //       callback(doc.data().unread_count);
  //     } else {
  //       callback(0);
  //     }
  //   });
};

/**
 *
 * @param studentId
 * @returns {Promise<*[]>}
 */
export const getActiveStudentSessions = (studentId) => {
  return require('../../firebase-config').db.collection('/blaze_dev/collections/blaze_sessions')
    .where('student_id', '==', studentId)
    .where('session_status', '!=', 'completed')
    .get()
    .then(querySnapshot => {
      let a = [];
      querySnapshot.forEach(c => a.push(c.data()))
      return a;
    })
}

/**
 * @param studentId
 * @description Will return the remaining minutes of particular student for today.
 */
export const usedMinutesForToday = async (studentId) => {
  const ist = await castIndianTime();
  return require('../../firebase-config').db.collection('/blaze_dev/collections/students/' + studentId + '/engagement')
    .doc(formatDateDoc(ist, false, true))
    .get()
    .then(c => {
      if(c.exists) {
        const d = c.data();
        if(!d.daily_engagement[formatDateDoc(ist)]) return 0;
        return Object.keys(d.daily_engagement[formatDateDoc(ist)]).reduce((acc, cur) => {
          acc += d.daily_engagement[formatDateDoc(ist)][cur].call_duration;
          return acc;
        }, 0);
      }
      console.log('Document not found in used Minutes.')
      return null;
    })
}

/// BookingPopup.js
export async function getFilterData(grade) {
  let filter_info_doc;
  var docRef = require('../../firebase-config').db
    .collection("blaze_dev")
    .doc('collections')
    .collection("cms_hierarchy")
    .doc(grade);

  await docRef
    .get()
    .then(async function (doc) {
      if (doc.exists) {
        filter_info_doc = doc;
      } else {
        // doc.data() will be undefined in this case
        filter_info_doc = null;
      }
    })
    .catch(function (error) {
      console.error(`Error ${error} while getFilterData for grade: ${grade}`);
    });

  return filter_info_doc;
}

export const getClassChapters = async (grade) => {
  let chapters;
  var docRef2 = require('../../firebase-config').db
    .collection("blaze_dev")
    .doc('collections')
    .collection("cms_hierarchy")
    .doc(grade);

  await docRef2
    .get()
    .then(function (doc) {
      if (doc.exists) {
        chapters = doc.data().subject_chapter_map;
      } else {
        // doc.data() will be undefined in this case
        chapters = null;
      }
    })
    .catch(function (error) {
      console.error(
        `Error ${error} while get Class Chapters for grade: ${grade}`
      );
    });

  return chapters;
};

const queryFormatter = ({ category, subject }) => {
  let query = null;

  if (category === "Maths" || category === "maths") {
    query = `${new Date().getFullYear()}_${category.toLowerCase()}_availability`;
  } else if (
    category !== null &&
    subject !== null &&
    typeof subject !== "undefined" &&
    subject !== "" &&
    subject !== ""
  ) {
    query = `${new Date().getFullYear()}_${category.toLowerCase()}_${subject
      .toLowerCase()
      .replace(" ", "_")}_availability`;
  }

  return query;
};

export const fetchAvailableDates = async ({ category, subject, grade }) => {
  if (queryFormatter({ category, subject }) !== null) {
    let _queryData = [];

    await require('../../firebase-config').db
      .collection("blaze")
      .doc(grade)
      .collection("blaze_availability")
      .doc(queryFormatter({ category, subject }))
      .collection(`${new Date().getFullYear()}_${new Date().getMonth() + 1}`)
      .doc("monthly_summary")
      .get()
      .then((e) => {
        if (e.exists) {
          _queryData.push(e.data()?.monthly_availability_count);
        } else {
          _queryData.push(null);
        }
      });

    await require('../../firebase-config').db
      .collection("blaze")
      .doc(grade)
      .collection("blaze_availability")
      .doc(queryFormatter({ category, subject }))
      .collection(`${new Date().getFullYear()}_${new Date().getMonth() + 2}`)
      .doc("monthly_summary")
      .get()
      .then((e) => {
        if (e.exists) {
          _queryData.push(e.data()?.monthly_availability_count);
        } else {
          let _dates = [];
          for (let i = 0; i < 31; i++) {
            _dates.push(0);
          }
          _queryData.push(_dates);
        }
      });

    return _queryData;
  }
};

export const fetchTimeSlots = async ({ date, grade, category, subject }) => {
  return await require('../../firebase-config').db
    .collection("blaze")
    .doc(grade)
    .collection("blaze_availability")
    .doc(queryFormatter({ category, subject }))
    .collection(`${date.getFullYear()}_${date.getMonth() + 1}`)
    .doc(`${date.getFullYear()}_${date.getMonth() + 1}_${date.getDate()}`)
    .collection("ts_availability_count")
    .doc("mapping")
    .get()
    .then((e) => {
      let _results = [];

      if (e.exists) {
        for (const [key, value] of Object.entries(e?.data()?.map)) {
          if (value > 0) {
            _results.push(key);
          }
        }
      }

      return _results;
    });
};

export const checkPriorBookings = async ({
                                           date,
                                           timeslots,
                                           grade,
                                           userid,
                                         }) => {
  let _tempslots = timeslots;

  const userBookings = await require('../../firebase-config').db
    .collection("blaze")
    .doc(grade)
    .collection("blaze_reservations")
    .doc(userid)
    .collection("reservations")
    .where("reservation_status", "==", "payment_captured")
    .get();

  try {
    userBookings.forEach((booking) => {
      const { day, hour, month, year } = booking?.data()?.session_start_time;

      if (
        year === new Date().getFullYear() &&
        month === date.getMonth() &&
        day === date.getDate() &&
        _tempslots.includes(hour)
      ) {
        _tempslots.splice(_tempslots.indexOf(hour), 1);
      }
    });
  } catch (e) {
    return _tempslots;
  }

  return _tempslots;
};

const uploadImage = async (file, sessionId, userId) => {
  var path = `blaze_sessions/${sessionId}/from-${userId}/${file.name}`;
  var _url = await require('../../firebase-config').storage
    .ref()
    .child(path)
    .put(await fetch(file.url).then((r) => r.blob()))
    .then(async (snapshot) => {
      return snapshot.ref.getDownloadURL().then((url) => url);
    });

  return _url;
};

export const requestSession = async ({
                                       skillId,
                                       studentId,
                                       studentName,
                                       profilePic,
                                       topic,
                                       doubtDetails,
                                       images,
                                       subject_color_gradient,
                                       refundCount,
                                       topic_cms_path,
                                       sessionCount,
                                     }) => {
  const docRef = require('../../firebase-config').db
    .collection("blaze_dev")
    .doc("collections")
    .collection("blaze_sessions")
    .doc();

  const requestId = docRef.id;
  const indianTime = await castIndianTime(true);
  const dateTimeObject = transformDateTimeObject(indianTime);

  await require('../../firebase-config').db
    .collection("blaze_dev")
    .doc("collections")
    .collection("students")
    .doc(studentId)
    .get()
    .then((result) => {
      if (!result.exists) {
        require('../../firebase-config').db.collection("blaze_dev")
          .doc("collections")
          .collection("students")
          .doc(studentId)
          .set({
            active_call_id: null,
            active_call_session_id: null,
            is_engaged: false,
            pending_rating_list: [],
            refund_count: 0,
            session_count: 0,
          });
      }
    })
    .catch((err) => console.log(err));

  const isDone = await docRef
    .set({
      accepted_ts: null,
      aggregated_duration: 0,
      aggregated_earnings: 0,
      completed_ts: null,
      id: requestId,
      instructor_id: null,
      instructor_name: null,
      instructor_profile_pic: null,
      requested_ts: indianTime.serverISOString,
      rtm_token: null,
      session_status: "outstanding",
      skill: skillId,
      subject_color_gradient: subject_color_gradient,
      student_id: studentId,
      student_name: studentName,
      student_profile_pic: profilePic ?? defaultPic,
      rating: null,
      student_refund_count: refundCount ? refundCount : 0,
      student_session_count: sessionCount ? sessionCount : 0,
      topic: topic,
      topic_cms_path
    })
    .then(async () => {
      if (images?.length > 0) {
        let _imageUrl = await uploadImage(images[0], requestId, studentId);

        let attachment = {
          attachment_name: images[0].name,
          attachment_type: "image",
          attachment_url: _imageUrl,
        };

        await docRef
          .collection("chats")
          .add({
            message: "",
            sender_id: studentId,
            sender_name: studentName,
            sender_profile_picture: profilePic,
            sent_on: indianTime.serverISOString,
            attachment: attachment,
            receiver_id: null,
            message_type: "attachment",
          });
      }
      if (doubtDetails?.trim().length > 0) {
        await docRef
          .collection("chats")
          .add({
            message: doubtDetails,
            sender_id: studentId,
            sender_name: studentName,
            sender_profile_picture: profilePic,
            sent_on: indianTime.serverISOString,
            attachment: null,
            receiver_id: null,
            message_type: "text",
          });
      }
    })
    .then(() => true)
    .catch(() => false);

  // if isDone is true then update the available_doubt_count within the user document for the current user
  if (isDone) {
    await require('../../firebase-config').db
      .collection("users")
      .doc(studentId)
      .set(
        {
          available_doubt_count: require('../../firebase-config').firebase.firestore.FieldValue.increment(-1),
        },
        { merge: true }
      );
  }

  return requestId;
};

const getMinuteValue = (timestamp) => {
  return (
    String(new Date(timestamp).getMinutes()) +
    String(new Date(timestamp).getHours())
  );
};

const groupMessages = (messages) => {
  let currentMinute = 0;
  let lastPosition = "bottom";

  for (let i = 0; i < messages?.length; i++) {
    currentMinute = getMinuteValue(messages[i]?.timestamp);
    let nextMsgMinute = 0;
    let prevMsgMinute = 0;
    if (i + 1 < messages.length) {
      nextMsgMinute = getMinuteValue(messages[i + 1]?.timestamp);
    } else {
      nextMsgMinute = "";
    }

    if (i > 0) {
      prevMsgMinute = getMinuteValue(messages[i - 1]?.timestamp);
    } else {
      prevMsgMinute = "";
    }

    // group messages logic
    if (messages[i] && messages[i].type !== "call_detail") {
      if (prevMsgMinute !== currentMinute && nextMsgMinute !== currentMinute) {
        messages[i]["position"] = "none";
        lastPosition = "bottom";
      } else if (currentMinute === nextMsgMinute && lastPosition === "bottom") {
        messages[i]["position"] = "top";
        lastPosition = "top";
      } else if (
        currentMinute === nextMsgMinute &&
        (lastPosition === "top" || lastPosition === "middle")
      ) {
        messages[i]["position"] = "middle";
        lastPosition = "middle";
      } else if (
        currentMinute !== nextMsgMinute &&
        (lastPosition === "top" || lastPosition === "middle")
      ) {
        messages[i]["position"] = "bottom";
        lastPosition = "bottom";
      } else if (currentMinute !== nextMsgMinute && i === messages.length - 1) {
        messages[i]["position"] = "bottom";
        lastPosition = "bottom";
      } else {
        messages[i]["position"] = "none";
        lastPosition = "bottom";
      }
    }
  }

  return messages;
};

export const groupBlazeMessages = (messages) => {
  let currentMinute = 0;
  let clusters = Array(messages.length * 2).fill(Array(0));
  let arrayIndex = 0;
  let prevFlag = false;

  for (let i = 0; i < messages?.length; i++) {
    let prevMsgMinute = 0;
    currentMinute = getMinuteValue(messages[i]?.timestamp);

    if (i > 0) {
      prevMsgMinute = getMinuteValue(messages[i - 1]?.timestamp);
    } else {
      prevMsgMinute = currentMinute;
    }

    if (messages[i]?.isByUser) {
      if (!prevFlag) {
        arrayIndex += 1;
        prevFlag = messages[i]?.isByUser;
      }

      if (currentMinute === prevMsgMinute) {
        clusters[arrayIndex] = [...clusters[arrayIndex], messages[i]];
      } else {
        arrayIndex += 1;
        clusters[arrayIndex] = [...clusters[arrayIndex], messages[i]];
      }
    } else {
      if (prevFlag) {
        arrayIndex += 1;
        prevFlag = messages[i]?.isByUser;
      }
      if (currentMinute === prevMsgMinute) {
        clusters[arrayIndex] = [...clusters[arrayIndex], messages[i]];
      } else {
        arrayIndex += 1;
        clusters[arrayIndex] = [...clusters[arrayIndex], messages[i]];
      }
    }
  }

  let groupedMessages = [];

  for (let i = 0; i < clusters.length; i++) {
    let groupedData = groupMessages(clusters[i]);
    groupedMessages = [...groupedMessages, ...groupedData];
  }
  return groupedMessages;
};

export const getGrade = (skill) => {
  const splitted = skill?.split("_");
  return "class_" + splitted[1];
};
