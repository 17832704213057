import {
  planet1,
  planet1_onboard,
  planet2,
  planet2_onboard,
  planet3, planet3_onboard,
  planet4,
  planet5,
  planet6,
  planet7,
  planet8
} from "../public/assets";
import axios from "./pustack-axios";
import {removeFcmTokenFromIndexedDb} from "../hooks/useAuth";

export const SCHOLARSHIP = {
  org_name: 'Jeet Foundation'
}

export const starPath =
  "M93.658,7.186,68.441,60.6,12.022,69.2C1.9,70.731-2.15,83.763,5.187,91.227l40.818,41.557-9.654,58.7c-1.738,10.611,8.959,18.559,17.918,13.6l50.472-27.718,50.472,27.718c8.959,4.922,19.656-2.986,17.918-13.6l-9.654-58.7,40.818-41.557c7.337-7.464,3.282-20.5-6.835-22.029L141.04,60.6,115.824,7.186A12.139,12.139,0,0,0,93.658,7.186Z";

export const VAPIDKEY =
  "BBAS6jVsiEE86EtJvP9RGSkPt46szXb2Ao7pfUdOL0xhhDiiPGnzgwN3utpw_O6RFMbuxgui2d3F7W98jFB5ZWk";

export const agoraAppID = "320f1867bc3d4922b4da5963a9f2b760";

export const PUSHY_SECRET_API_KEY =
  "73572a9672a97ef4af6e38167d99d87825d53642114ff16780f3082f9973c16f";

export const FCM_AUTH_HEADER =
  "key=AAAAmhuAeFI:APA91bHSnIpAbEKtDTjvZH1F3xtJvHIlz_m1GOOgzbtZdsiqXpUk7QZvVdKs_9PIbnENdz_ytxaEjFqihGA9MZsW7S6FS1WsOOTDfawNdW_6iVbeduNZOxMVwfEzM69QpHsEVouUQFyd";

export const firebaseAPiKey = "AIzaSyCgfeFcXVvvuIp79IJD8KCahJo2PzrHDco";

export const firebaseDbURL = "https://avian-display-193502.firebaseio.com";

export const appGooglePlayLink =
  "https://play.google.com/store/apps/details?id=com.pustack.android.pustack&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";

export const appAppleLink = "https://apps.apple.com/app/pustack/id6444080075";

export const appGooglePlayTutorLink = "https://play.google.com/store/apps/details?id=com.pustack.android.pustacktutor";

export const appAppleTutorLink = "https://apps.apple.com/in/app/pustack-tutor/id6444847124";

export const googlePlayBadge =
  "https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png";

export const ALREADY_EXIST_PHONE_LOGIN_CODE = 'already-exists-phone_login';
export const ALREADY_EXIST_EMAIL_LOGIN_CODE = 'already-exists-email_login'

export const privacyPolicy =
  "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/legal%2FPrivacy%20Policy.pdf?alt=media&token=7233bfb7-dd7b-4587-ba0b-e72bc78bbe4d";

export const termsOfService =
  "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/legal%2FTerms%20of%20Service.pdf?alt=media&token=c108b0eb-31f0-4101-8a4a-096e0ec00f8f";

export const refundAndCancellationPolicy =
  "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/legal%2FRefund%20Policy.pdf?alt=media&token=b75e6bbb-91d4-47fd-9fb4-e1c63ab40e7e"


export const getGradeNameByValue = (value) => {
  if(!value) return;
  const isPresent = getAvailableGrades().find(c => c.value === value);
  if(!isPresent) throw new Error('This Grade "' + value + '" is not supported yet. Please contact Pustack administrator');
  return isPresent.grade;
}

export const getAvailableGrades = (reduced, excludeClass2, isProduction) => {
  // console.log('planet1 - ', planet1);

  let grades = [
    {grade: "Class 5", value: "class_5", planet: planet1_onboard},
    {grade: "Class 6", value: "class_6", planet: planet2_onboard},
    {grade: "Class 7", value: "class_7", planet: planet3_onboard},
    {grade: "Class 8", value: "class_8", planet: planet4},
    {grade: "Class 9", value: "class_9", planet: planet5, standard: true, enableToSelect: true},
    {grade: "Class 10", value: "class_10", planet: planet6, standard: true, enableToSelect: true},
    {grade: "Class 11", value: "class_11", planet: planet7, standard: true},
    {grade: "Class 12", value: "class_12", planet: planet8, standard: true}
  ];

  if(!excludeClass2) grades.splice(0,0,
    {grade: "Class 2", value: "class_2"},
  )

  if(isProduction) {
    grades = [
      {grade: "Class 9", value: "class_9", planet: planet5, standard: true, enableToSelect: true},
      {grade: "Class 10", value: "class_10", planet: planet6, standard: true, enableToSelect: true},
    ]
  }

  if(reduced) return grades.map(c => c.value);
  return grades;

  // let gradeCollection = 'grades_dev';
  // // let gradeCollection = process.env.NODE_ENV === 'production' ? 'grades' : 'grades_dev';
  // const snapshot = await db
  //   .collection(gradeCollection)
  //   .doc('available_grades')
  //   .get();
  //
  // return snapshot.exists ? snapshot.data() : {};
}


export const loadingWrapper = () => {
  // document.querySelector(".loading__wrapper").style.display = "flex";
  document.body.style.position = "fixed";
  document.body.style.top = `-${window.scrollY}px`;

  setTimeout(() => {
    // document.querySelector(".loading__wrapper").style.display = "none";

    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  }, 3000);
};

export const getYoutubeID = (url) => {
  const regExp =
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  const match = url.match(regExp);
  return match && match[1].length === 11 ? match[1] : false;
};

export const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export const RTMConfig = {
  appId: '320f1867bc3d4922b4da5963a9f2b760'
}

export const RTCConfig = {
  appId: '320f1867bc3d4922b4da5963a9f2b760'
}

export const WHITEBOARConfig = {
  appIdentifier: 'VHBvQEEXEeuIHrEufR7KaQ/cMKLzXlzkZaSoQ'
}

export const baseUrl = () => {
  if(process.env.NODE_ENV === "production") return 'https://us-central1-avian-display-193502.cloudfunctions.net'
  // return 'http://localhost:5001/avian-display-193502/us-central1'
  return 'https://us-central1-avian-display-193502.cloudfunctions.net'
}

export const AGORA_ROUTES = {
  getRTMToken: '/getRTMToken',
  getRTCToken: '/getRTCToken',
  createRoom: '/createRoom',
  getRoomToken: '/getRoomToken',
  getTaskToken: '/getTaskToken',
  uploadFileToS3: '/uploadFileToS3',
  fileConversionAgora: '/fileConversionAgora',
}

export const getGrade = (skill) => {
  const splitted = skill?.split("_");
  return "class_" + splitted[1];
};

export const getCurrentVersion = async () => {
  return require('../firebase-config').db
    .collection("web_version")
    .doc("student_web")
    .get()
    .then((doc) => doc.data())
    .catch((err) => console.log(err));
};

const isValidFCMToken = async (token) => {
  const response = await axios.get('https://iid.googleapis.com/iid/info/' + token, {
    headers: {
      'Authorization': 'Bearer AAAAmhuAeFI:APA91bHSnIpAbEKtDTjvZH1F3xtJvHIlz_m1GOOgzbtZdsiqXpUk7QZvVdKs_9PIbnENdz_ytxaEjFqihGA9MZsW7S6FS1WsOOTDfawNdW_6iVbeduNZOxMVwfEzM69QpHsEVouUQFyd'
    }
  });
  console.log('response - ', response);
  return !Boolean(response.data.error);
}

export const fetchFCMToken = async () => {
  let token = localStorage.getItem('fcmToken');
  if(!token) {
    token = await require('../firebase-config').messaging.getToken({vapidKey: VAPIDKEY});
  }
  const isTokenValid = await isValidFCMToken(token);
  console.log('isTokenValid - ', isTokenValid);
  if(!isTokenValid) {
    await removeFcmTokenFromIndexedDb();
    token = await require('../firebase-config').messaging.getToken({vapidKey: VAPIDKEY});
  }
  localStorage.setItem('fcmToken', token);
  return token;
}

export const removeRingerFromWeb = async (uid) => {
  const snapshot = await require('../firebase-config').db.collection('user_tokens')
    .doc(uid)
    .get();

  const data = snapshot.data();
  let tokens = data.tokens;

  if(!tokens) {
    tokens = [];
  }

  const webTokens = tokens.filter(c => c.platform === 'web' && c.app === 'pustack_app')
    .map(c => c.token);

  axios.post(
    "https://fcm.googleapis.com/fcm/send",
    {
      data: {
        notification_type: 'dismiss_ringer'
      },
      registration_ids: webTokens,
    },
    { headers: { Authorization: FCM_AUTH_HEADER } }
  ).catch((err) => console.log(err));
}

export const sortedISOString = function(arr, nestedKey = null, inAsc = false) {
  const arrSorted = arr.sort((a, b) => {
    let keys = [a, b];
    if(nestedKey) keys = [a[nestedKey], b[nestedKey]];
    if (isNaN(keys[0])) return -1;
    return 0;
  });

  return arrSorted.sort((a, b) => {
    let keys = [a, b];
    if(nestedKey) keys = [a[nestedKey], b[nestedKey]];
    if(keys[0] < keys[1]) return 1 * (inAsc ? -1 : 1);
    if(keys[0] > keys[1]) return -1 * (inAsc ? -1 : 1);
    return 0;
  })
}

export const isNameValid = (str = "") => {
  if(!(str.trim().length > 0)) return;
  let regex = "^[a-zA-Z ]*$";
  return !!str.match(regex);
}

export const commonPages = [
  {path: "/sitemap", noLoader: true},
  {path: "/", noLoader: true},
  {path: "/contact", noLoader: true},
  {path: "/about", noLoader: true},
  {path: "/privacy_policy", noLoader: false},
  {path: "/cancellation_policy", noLoader: false},
  {path: "/terms_of_service", noLoader: false},
  {path: "/app", noLoader: true},
  {path: "/lottie", noLoader: true}
]

export const publicPages = [
  // {path: "/classroom", noLoader: false},
  // {path: "/tips/demo", noLoader: false},
  // {path: "/practice/demo", noLoader: false},
];

export const removeSafariToken = async (userId) => {
  if(!window || !navigator.serviceWorker) return;
  const url = window.location.origin + '/web-worker.js'
  navigator.serviceWorker.getRegistrations().then(registrations => {
    const myRegistration = registrations.find(c => c.active.scriptURL === url);
    if(!myRegistration) {
      return;
    }

    console.log('myRegistration - ', myRegistration);

    // myRegistration.showNotification('My notification title', {
    // 	body: 'Notified by Saurabh',
    // 	icon: 'http://image.ibb.co/frY0Fd/tmlogo.png'
    // })


    myRegistration.pushManager.getSubscription()
      .then(async (subscription) => {
        console.log('subscription - ', subscription);

        if(subscription && userId) {
          const snapshot = await require('../firebase-config').db.collection('user_tokens')
            .doc(userId)
            .get();

          const tokens = snapshot.data().tokens ?? [];

          console.log('tokens - ', tokens);

          const filteredTokens = tokens.filter(c => c.subscription?.endpoint !== JSON.parse(JSON.stringify(subscription)).endpoint);

          console.log('tokens - ', tokens);

          await require('../firebase-config').db.collection('user_tokens')
            .doc(userId)
            .set({
              tokens: filteredTokens
            });
        }
      })
  })


}

export const setSafariToken = async (userId, subscription) => {
  const snapshot = await require('../firebase-config').db.collection('user_tokens')
    .doc(userId)
    .get();

  const tokens = snapshot.data().tokens ?? [];

  console.log('tokens - ', tokens);

  const isAlreadyThere = tokens.find(c => c.subscription?.endpoint === JSON.parse(JSON.stringify(subscription)).endpoint);
  if(isAlreadyThere) return;

  tokens.push({
    subscription: JSON.parse(JSON.stringify(subscription)),
    last_seen: Date.now(),
    platform: 'safari_web',
    app: 'pustack_app'
  })

  console.log('tokens - ', tokens);

  await require('../firebase-config').db.collection('user_tokens')
    .doc(userId)
    .set({
      tokens
    });
}

export const SNACKBAR_TYPES = ['success', 'warning', 'help', 'error']

export const capitalize = (a) => {
  if(!a) return 'No Word';
  return a.split(' ').reduce((acc, cur, ind) => {
    if(cur.length === 0) return acc;
    let newStr = cur.substr(0, 1).toUpperCase() + cur.substr(1).toLowerCase()
    acc.push(newStr);
    return acc
  }, []).join(' ')
}
