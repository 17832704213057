import React, { useState, useContext, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import SwipeableViews from "react-swipeable-views";
import {useRouter} from 'next/router';
import {CategorySelection as BlazeCategorySelector} from "./CategorySelector";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {formatDateDoc} from '../../database/livesessions/sessions';
import {requestSession, subjectColorsMeta} from '../../database/blaze';
import Image from 'next/image';
import {
	BlazeSessionContext,
	BookSessionContext, PustackProContext,
	IntroContext,
	ThemeContext,
	UserContext,
} from "../../context";

import imageGallery from "../../public/assets/images/blaze/imageGallery.svg";
// import {useHistory} from "react-router-dom";
import {castIndianTime} from '../../helpers/getIndianTime';
import {useMediaQuery} from "react-responsive";
import useSnackbar from "../../hooks/snackbar/useSnackbar";

function ThreeDotsLoader() {
	return (
		<div className="lds-ellipsis">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
}

const usedMinutesForToday = async (studentId) => {
	const ist = await castIndianTime();
	return require('../../firebase-config').db.collection('/blaze_dev/collections/students/' + studentId + '/engagement')
		.doc(formatDateDoc(ist, false, true))
		.get()
		.then(c => {
			if(c.exists) {
				const d = c.data();
				if(!d.daily_engagement[formatDateDoc(ist)]) return 0;
				return Object.keys(d.daily_engagement[formatDateDoc(ist)]).reduce((acc, cur) => {
					acc += d.daily_engagement[formatDateDoc(ist)][cur].call_duration;
					return acc;
				}, 0);
			}
			console.log('Document not found in used Minutes.')
			return null;
		})
}

const getActiveStudentSessions = (studentId) => {
	return require('../../firebase-config').db.collection('/blaze_dev/collections/blaze_sessions')
		.where('student_id', '==', studentId)
		.where('session_status', '!=', 'completed')
		.get()
		.then(querySnapshot => {
			let a = [];
			querySnapshot.forEach(c => a.push(c.data()))
			return a;
		})
}

const useForceUpdate = () => {
	const [, setValue] = useState(0);
	return () => setValue((value) => ++value);
};

export default function BookingPopup() {
	const [isOpen, setIsPopupOpen] = useContext(BlazeSessionContext).openBookingPopup;
	const forceUpdate = useForceUpdate();
	const history = useRouter();

	const [isProTier] = useContext(UserContext).tier;
	const [_, setShowWarning] = useContext(PustackProContext).warning;
	const [, setIsOpen] = useContext(PustackProContext).doubtValue;
	const isSmallScreen = useMediaQuery({ query: "(max-width: 500px)" });

	const [allowNext, setAllowNext] = useContext(BookSessionContext).allowNext;
	const blazeSessionCreationJourneyRef = useContext(IntroContext).blazeSessionCreationJourneyRef;
	const [subjectColors, setSubjectColors] = useContext(BlazeSessionContext).subjectColors;
	const [studentActivity] = useContext(BlazeSessionContext).studentActivity;
	const [maxTimePerDay] = useContext(BlazeSessionContext).maxTimePerDay;
	const [categorySelected, setCategorySelected] =
		useContext(BookSessionContext).categorySelected;
	const [subjectSelected, setSubjectSelected] =
		useContext(BookSessionContext).subjectSelected;
	const [chapterSelected, setChapterSelected] =
		useContext(BookSessionContext).chapterSelected;
	const [, setDoubtSliderOpen] = useContext(PustackProContext).doubtValue;

	const [user] = useContext(UserContext).user;
	const [isDark] = useContext(ThemeContext).theme;

	const [currentTabIndex, setCurrentTabIndex] = useState(0);
	const [isNextAllowed, setIsNextAllowed] = useState(false);
	const [doubtDetails, setDoubtDetails] = useState("");
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [images, setImages] = useState([]);
	const [message, setMessage] = useState("");
	const {show} = useSnackbar();
	const [openSnack, setOpenSnack] = useState(false);

	const handleClose = () => {
		setIsPopupOpen(false);
	}

	const handlePopupClose = (e, close) => {
		console.log(!isSubmitting || close);
		if(!isSubmitting || close) {
			setAllowNext(false);
			setCurrentTabIndex(0);
			setSubjectSelected("");
			setChapterSelected("");
			setIsNextAllowed(false);
			setCategorySelected("General");
			setDoubtDetails("");
			handleClose();
			setImages([]);
			setOpenSnack(false);
			setMessage("");
		}
	};

	// useEffect(() => {
	//   if(isSubmitting) document.body.style.pointerEvents = 'none';
	//   else document.body.style.pointerEvents = 'all';
	// }, [isSubmitting])
	useEffect(() => {
		const subjectColorsMetaFn = async () => {
			setSubjectColors(await subjectColorsMeta({ grade: user?.grade }));
		};

		if (user?.grade) subjectColorsMetaFn();
	}, [user?.grade]);

	useEffect(() => {
		if (currentTabIndex === 0) {
			if (allowNext && (subjectSelected || categorySelected === "Maths")) {
				setIsNextAllowed(true);
			} else {
				setIsNextAllowed(false);
			}
		} else {
			if (doubtDetails?.trim().length > 10 || images.length > 0) {
				setIsNextAllowed(true);
			} else {
				setIsNextAllowed(false);
			}
		}
	}, [
		allowNext,
		currentTabIndex,
		subjectSelected,
		categorySelected,
		doubtDetails,
		images,
	]);

	const requestSessionFn = async () => {
		if(isSubmitting) return;



		const available_doubt_count = user.available_doubt_count ?? 0;

		if(available_doubt_count === 0) {
			// show({
			// 	title: 'Doubt Count Exceeded',
			// 	description: 'Please buy doubt count to create a session.',
			// 	type: 'warning'
			// })
			handlePopupClose(null, true);
			return setIsOpen(true);
		}

		setIsSubmitting(true);
		let _skillId = "";
		if (categorySelected === "Maths") {
			_skillId = user?.grade + "_maths";
		} else {
			_skillId =
				user?.grade +
				"_" +
				categorySelected.toLowerCase() +
				"_" +
				subjectSelected.replace(" ", "_").toLowerCase();
		}

		let gradient = subjectColors[categorySelected === "Maths" ? categorySelected : subjectSelected];
		if(!gradient) {
			gradient = subjectColors['Exam Prep.'];
		}

		const res = await requestSession({
			skillId: _skillId,
			studentId: user?.uid,
			studentName: user?.name,
			profilePic: user?.profile_url,
			topic: chapterSelected ? chapterSelected.name : subjectSelected ? subjectSelected : categorySelected === "Maths" ? "Mathematics" : "No Topic",
			topic_cms_path: chapterSelected ? chapterSelected.id : null,
			doubtDetails,
			images,
			refundCount: studentActivity?.refundCount,
			sessionCount: studentActivity?.sessionCount,
			subject_color_gradient: gradient
		});

		if (res) {
			blazeSessionCreationJourneyRef.current.logEvent('has_requested_blaze_session', null, 'screen_view');

			setIsSubmitting(false);
			handlePopupClose();
			history.push('/blaze/chat/' + res);
		}
	};

	const imageSelectionHandler = (e) => {
		const { files } = e.target;

		let _images = [];

		let _num =
			files.length + images.length > 1
				? images.length - files.length > 0
					? images.length - files.length
					: 1
				: files.length;

		if (files.length + images.length > 1) {
			setMessage("Only 1 image is allowed at a time");
			setOpenSnack(true);
			setTimeout(() => setOpenSnack(false), 2500);
		}

		if (images.length !== 1) {
			for (let i = 0; i < _num; i++) {
				_images[i] = {
					url: URL.createObjectURL(files[i]),
					ext: files[i].name.split(".").slice(-1)[0],
					name: files[i].name,
				};
			}
			setImages(images.concat(_images));
		}
		e.target.value = "";
	};

	const handleImageDelete = () => setImages([]);

	const handleDoubtInput = (e) => {
		let value = e.target.value;
		if(value.trim().length > 350) {
			return;
			// value = value.trim().slice(0, 350);
		}
		setDoubtDetails(value);
	}

	useEffect(() => {
		if(isOpen && blazeSessionCreationJourneyRef && blazeSessionCreationJourneyRef.current) {
			blazeSessionCreationJourneyRef.current.logEvent('on_create_blaze_session_button_tap');
		}
	}, [blazeSessionCreationJourneyRef, isOpen]);

	useEffect(() => {
		if(!isOpen || !blazeSessionCreationJourneyRef || !blazeSessionCreationJourneyRef.current) return () => {};
		if(currentTabIndex === 0) {
			blazeSessionCreationJourneyRef.current.logEvent('screen_view', {
				app_name: 'pustack_app',
				screen_name: 'blaze_subject_selection_screen'
			}, 'on_create_blaze_session_button_tap');
		} else if(currentTabIndex === 1) {
			blazeSessionCreationJourneyRef.current.logEvent('screen_view', {
				app_name: 'pustack_app',
				screen_name: 'add_blaze_session_details_screen'
			});
		}
	}, [blazeSessionCreationJourneyRef, isOpen, currentTabIndex])

	return (
		<div className="blaze__book__popup">
			<Dialog
				className={
					isDark
						? "blaze__book__modal blaze__book__modal__dark"
						: "blaze__book__modal"
				}
				disableBackdropClick={currentTabIndex === 1}
				open={isOpen}
				// open={true}
				onClose={handlePopupClose}
				disableEscapeKeyDown={true}
			>
				<div
					className="popup__title"
					style={{
						position: "relative",
						background: isDark ? "#141414" : "#FFFFFF",
					}}
				>
					{currentTabIndex === 1 && (
						<div
							className="popup-back-button"
							style={{ position: "absolute", top: "1rem", left: ".5rem" }}
						>
							<ArrowBackIosIcon
								onClick={() => {
									if(!isSubmitting) setCurrentTabIndex(0);
								}}
								style={{ cursor: "pointer" }}
							/>
						</div>
					)}
					<div
						className="popup-title-label"
						style={{ color: isDark ? "#FFFFFF" : "#891010" }}
					>
						{currentTabIndex === 0 ? "Request Session" : "Add Details"}
					</div>

					<div
						className="popup-close-button"
						style={{ position: "absolute", top: "1rem", right: ".5rem" }}
					>
						<CloseIcon
							onClick={handlePopupClose}
							style={{ cursor: "pointer", height: "20px" }}
						/>
					</div>
				</div>

				<SwipeableViews
					axis="x"
					index={currentTabIndex}
					scrolling={"false"}
					containerStyle={{
						background: isDark ? "#141414" : "var(--color-primary)",
						transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s'
					}}
					style={{
						background: isDark ? "#141414" : "var(--color-primary)",
					}}
					slideStyle={{
						background: isDark ? "#141414" : "var(--color-primary)",
					}}
					disabled={true}
					ignoreNativeScroll={true}
				>
					<div
						className={
							isDark ? "blaze__popup__content dark" : "blaze__popup__content"
						}
						style={{ background: isDark ? "#141414" : "#FFFFFF" }}
					>
						<div className="popup__category__picker">
							<BlazeCategorySelector
								forceUpdate={forceUpdate}
								isDark={isDark}
							/>
						</div>
					</div>
					<div
						className={
							isDark ? "blaze__popup__content dark" : "blaze__popup__content"
						}
						style={{ background: isDark ? "#141414" : "#FFFFFF" }}
					>
						<div className="popup__category__picker">
							<p
								className={
									isDark
										? "doubtTile__topLevel doubtTile__topLevel__dark"
										: "doubtTile__topLevel"
								}
								style={{ paddingLeft: "15px" }}
							>
								{categorySelected}
								{subjectSelected ? (
									<span
										className={
											isDark
												? "doubtTile__subject doubtTile__subject__dark"
												: "doubtTile__subject"
										}
										style={{ marginLeft: "2px" }}
									>{` •  ${subjectSelected}`}</span>
								) : (
									<></>
								)}
								{chapterSelected ? (
									<span
										style={{
											marginLeft: "2px",
											color: isDark ? "white" : "black",
										}}
									>{` •  ${chapterSelected.name}`}</span>
								) : (
									<></>
								)}
							</p>
							<textarea
								rows="6"
								placeholder="What is your doubt?"
								autoComplete={false}
								autoFocus
								name="details"
								value={doubtDetails}
								// disabled={images.length > 0 || isSubmitting}
								disabled={isSubmitting}
								onChange={handleDoubtInput}
								className="doubt__details__input"
							/>
							<div className="image__picker">
								<p style={{ paddingLeft: "15px" }}>
									<label htmlFor="image-picker" style={{cursor: "pointer"}}>
										<div>
											<Image
												className="image__picker__label"
												src={imageGallery}
											/>
										</div>
									</label>
									<input
										className="booking__image__picker"
										accept="image/*"
										disabled={isSubmitting}
										type="file"
										style={{ display: "none" }}
										id="image-picker"
										onChange={imageSelectionHandler}
									/>
								</p>
								<p className="character__count">{doubtDetails.trim().length} / 350</p>
							</div>
						</div>
						<div className="blaze__chat__image__preview fadeOutDown">
							{images.length > 0 &&
								images.map((image) => (
									<div className="image__preview">
										<CloseIcon
											onClick={handleImageDelete}
											className="imagePreviewDialog_closeIcon"
										/>
										<Image width={100} height={100} src={image.url} alt="X" draggable={false} />
									</div>
								))}
						</div>
						{openSnack && <div className="booking__warnings">{message}</div>}
					</div>
				</SwipeableViews>
				{currentTabIndex === 0 && (
					<div
						className="blaze__popup__action"
						style={{ background: isDark ? "#141414" : "#FFFFFF" }}
					>
						<button
							disabled={!isNextAllowed}
							onClick={() => setCurrentTabIndex(1)}
							className={
								isDark ? "blaze__popup__button dark" : "blaze__popup__button"
							}
							aria-label="blaze__popup__button"
						>
							Add Details
						</button>
					</div>
				)}
				{currentTabIndex === 1 && (
					<div
						className="blaze__popup__action"
						style={{ background: isDark ? "#141414" : "#FFFFFF" }}
					>
						<button
							disabled={!isNextAllowed}
							onClick={requestSessionFn}
							className={
								isDark ? "blaze__popup__button dark" : "blaze__popup__button"
							}
							aria-label="blaze__popup__button"
						>
							{isSubmitting ? <ThreeDotsLoader /> : "Submit Request"}
						</button>
					</div>
				)}
			</Dialog>
		</div>
	);
}
