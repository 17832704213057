export const PAGE_TYPES = {
	LIVE_CLASSES: 'live_classes'
}

export const SIDEBAR_UI_CONSTANTS = {
	TIMELINE_LABEL_WIDTH: 58,
	TIMELINE_HORIZONTAL_PADDING: 12
}

export const dynamicLinkPrefix = 'https://pustack.page.link';
