import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import Modal from 'react-modal';
import Image, {StaticImageData} from 'next/image';
import {Stars1, Stars2, Stars3, Stars4, captain, cadet, explorer, lieutenant, sergeant} from '../../public/assets';
import {UserContext} from '../../context';
import {z} from 'zod';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';

const badgesData = [
	{id: 'cadet', serialOrder: 1, name: 'Cadet', lectureCount: 1, icon: cadet},
	{id: 'explorer', serialOrder: 2, name: 'Explorer', lectureCount: 15, icon: explorer},
	{id: 'sergeant', serialOrder: 3, name: 'Sergeant', lectureCount: 50, icon: sergeant},
	{id: 'lieutenant', serialOrder: 4, name: 'Lieutenant', lectureCount: 150, icon: lieutenant},
	{id: 'captain', serialOrder: 5, name: 'Captain', lectureCount: 500, icon: captain}
]

export class Badge {
	name: string;
	lectureCount: number;
	serialOrder: number;
	icon: StaticImageData;
	private _progress: number;
	private _description: string;
	private _isUpcoming: boolean;

	get isUpcoming() {
		return this._isUpcoming;
	}

	get completed() {
		return this._progress >= 1;
	}

	get progress() {
		return this._progress
	}

	get description() {
		return this._description
	}

	constructor(name: string, lectureCount: number, serialOrder: number, icon: StaticImageData) {
		this.name = name;
		this.lectureCount = lectureCount;
		this.serialOrder = serialOrder;
		this.icon = icon;
	}

	setProgress(watchedCount: number, previousBadge: Badge) {
		const lastBadgeLimit = previousBadge ? previousBadge.lectureCount : 0;
		this._progress = parseFloat(((watchedCount - lastBadgeLimit) / (this.lectureCount - lastBadgeLimit)).toFixed(2));
		if(this._progress >= 1) {
			this._progress = 1;
			this._description = 'Completed ' + (this.lectureCount > 1 ? ' ' + this.lectureCount + ' lectures' : ' 1 lecture');
		} else {
			if(this._progress < 0) this._progress = 0;
			this._description = 'Watch ' + (this.lectureCount - watchedCount) + ' more lecture' + (this.lectureCount - watchedCount > 1 ? 's' : '');
		}

		// Check if the previous badge is completed
		this._isUpcoming = previousBadge ? previousBadge.completed && this._progress < 1 : this._progress < 1;
	}
}

export class Badges {
	private readonly badges: Badge[];
	private _watchedCount = 0;
	private _newBadge: Badge | null | undefined = null;

	get watchedCount() {
		return this._watchedCount;
	}

	get newBadge() {
		return this._newBadge;
	}

	get currentBadge(): Badge | null | undefined {
		return this.getBadges().filter(badge => badge.completed).pop();
	}

	constructor() {
		this.badges = [];
	}

	addBadge(badge: Badge) {
		this.badges.push(badge);
	}

	getBadges() {
		return this.badges.sort((a, b) => a.serialOrder - b.serialOrder);
	}

	getBadge(serialOrder: number) {
		return this.badges.find(badge => badge.serialOrder === serialOrder);
	}

	// I want to create a method to let it know that new badge animation is done
	// So that I can set the new badge to null
	newBadgeAnimationDone() {
		this._newBadge = null;
	}

	updateWatchedCount(watchedCount: number) {
		this._watchedCount = watchedCount;
		this.badges.map(badge => {
			const prevProgress = badge.progress;

			badge.setProgress(watchedCount, this.getBadge(badge.serialOrder - 1));

			if(prevProgress < 1 && badge.progress >= 1) {
				this._newBadge = badge;
			}
		})
	}
}

interface UserBadgesModalProps {

}

function TickItem() {
	return (
		<div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-40">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={16}
				// height={9}
				viewBox="0 0 12 9"
				fill="none"
			>
				<path
					stroke="#fff"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1.5}
					d="m.75 4.75 3.5 3.5 7-7.5"
				/>
			</svg>
		</div>
	)
}

const monthlyEngagementZod = z.object({
	monthly_engagement: z.record(z.string(), z.object({
		total_spent_time: z.number(),
		total_watched_lecture_count: z.number()
	}))
})

const UserBadgesModal: FC<UserBadgesModalProps> = (props) => {
	const [user] = useContext(UserContext).user;
	const [openBadgeModal, setOpenBadgeModal] = useContext(UserContext).openBadgeModal;
	const [currentBadge, setCurrentBadge] = useContext(UserContext).currentBadge;
	const [newBadge, setNewBadge] = useContext(UserContext).newBadge;
	const { width, height } = useWindowSize();
	const badgesRef = useContext(UserContext).userBadgesRef as React.MutableRefObject<Badges>;
	const [totalWatchedCount, setTotalWatchedCount] = useState(0);
	// const badgesRef = useRef<Badges>(null);

	useEffect(() => {
		badgesRef.current = new Badges();
		badgesData.forEach(badge => {
			badgesRef.current?.addBadge(new Badge(badge.name, badge.lectureCount, badge.serialOrder, badge.icon));
		})

		// Debugging the new badge animation
		// setNewBadge(badgesRef?.current.getBadge(2));
	}, [badgesRef])

	useEffect(() => {
		if(!user.uid) return;

		const unsub = require('../../firebase-config')
			.db
			.doc('user_engagement/daily_engagement')
			.collection(user.uid)
			.doc('lifetime')
			.onSnapshot(snapshot => {
				console.log('snapshot - ', snapshot);
				if(!snapshot.exists) return;
			  const data = snapshot.data();

				const validatedData = monthlyEngagementZod.safeParse(data);

				console.log('Total watch count - ', validatedData);

				if(!validatedData.success) return;

				const monthlyEngagement = validatedData.data.monthly_engagement;
				if(!monthlyEngagement) return;
				const count = Object.values(monthlyEngagement).reduce((acc, curr) => {
					return acc + curr.total_watched_lecture_count;
				}, 0);

				console.log('Total watch count - ', count);

				badgesRef.current?.updateWatchedCount(count);

				setTotalWatchedCount(count);

				setCurrentBadge(badgesRef?.current.currentBadge);
				setNewBadge(badgesRef?.current.newBadge);
			})

		return () => {
			unsub();
		}
	}, [badgesRef, setCurrentBadge, setNewBadge, user.uid])

	return (
		<>
			<Modal
				shouldCloseOnEsc={true}
				shouldCloseOnOverlayClick={true}
				onRequestClose={() => setOpenBadgeModal(false)}
				ariaHideApp={false}
				className="absolute rounded-lg star-background top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[90vw] max-h-[90vh] max-w-[400px] h-auto bg-white dark:bg-black p-2"
				overlayClassName="new-post-modal-overlay dark z-[9898988998998]"
				isOpen={openBadgeModal}
			>
				<div className="absolute pointer-events-none top-0 left-0 w-full h-full opacity-50">
					<Image className="absolute top-0 left-[5%] animate-float" style={{zoom: 0.2}} src={Stars1} alt={'stars'} />
					<Image className="absolute top-[10%] animate-float" style={{zoom: 0.2}} src={Stars4} alt={'stars'} />
					<Image className="absolute top-[30%] left-[10%] animate-float" style={{zoom: 0.2}} src={Stars2} alt={'stars'} />
					<Image className="absolute top-[50%] left-[5%] animate-float" style={{zoom: 0.2}} src={Stars1} alt={'stars'} />
					<Image className="absolute top-[70%] left-[15%] animate-float" style={{zoom: 0.2}} src={Stars3} alt={'stars'} />
					<Image className="absolute top-0 left-[45%] animate-float" style={{zoom: 0.2}} src={Stars2} alt={'stars'} />
					<Image className="absolute top-[30%] left-[50%] animate-float" style={{zoom: 0.2}} src={Stars1} alt={'stars'} />
					<Image className="absolute top-[50%] left-[45%] animate-float" style={{zoom: 0.2}} src={Stars4} alt={'stars'} />
					<Image className="absolute top-[70%] left-[60%] animate-float" style={{zoom: 0.2}} src={Stars3} alt={'stars'} />
				</div>
				<div className="pb-6 px-4">
					<div className="pt-4 pb-6">
						<h2 className="text-xl text-white font-medium">Badges</h2>
					</div>

					{badgesRef.current?.getBadges().map((badge, index) => {
						return (
							<div key={badge.serialOrder}>
								{badge.lectureCount > 1 && <div className="w-12 flex justify-center">
									<div className="h-[50px] w-1 bg-gray-300">
										<div className="w-full bg-[#087c30]" style={{height: `${badge.progress * 100}%`}}/>
									</div>
								</div>}
								<div className="flex items-center gap-4">
									<div className={'w-12 h-12 relative rounded-full bg-[#891010] overflow-hidden ' + (badge.isUpcoming ? 'green-shadow-anim' : '')}>
										<Image className="object-cover w-full h-full" src={badge.icon} alt="Space Cadet"/>
										{badge.completed && <TickItem />}
									</div>
									<div className={badge.completed ? 'opacity-40' : ''}>
										<h3 style={{color: badge.isUpcoming ? '#16ad4a' : '#ffffff'}} className="text-white font-semibold font-roboto">{badge.name}</h3>
										<p className="mt-1 text-xs text-white text-opacity-40">{badge.description}</p>
									</div>
								</div>
							</div>
						)
					})}

					{/*<div>*/}
					{/*	<div className="w-12 flex justify-center">*/}
					{/*		<div className="h-[50px] w-1 bg-gray-300">*/}
					{/*			<div className="w-full h-[100%] bg-[#087c30]" />*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*	<div className="flex items-center gap-4">*/}
					{/*		<div className="w-12 h-12 relative rounded-full bg-[#891010] overflow-hidden">*/}
					{/*			<Image className="object-cover w-full h-full" src={explorer} alt="Space Cadet"/>*/}
					{/*			<TickItem />*/}
					{/*		</div>*/}
					{/*		<div className="opacity-40">*/}
					{/*			<h3 className="text-white font-semibold font-roboto">Explorer</h3>*/}
					{/*			<p className="mt-1 text-xs text-white text-opacity-40">Completed 15 lectures</p>*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*</div>*/}


					{/*<div className="flex items-center gap-4">*/}
					{/*	<div className="w-12 h-12 rounded-full relative bg-[#891010] overflow-hidden">*/}
					{/*		<Image className="object-cover w-full h-full" src={cadet} alt="Space Cadet"/>*/}
					{/*		<TickItem />*/}
					{/*	</div>*/}
					{/*	<div className="opacity-40">*/}
					{/*		<h3 className="text-white font-semibold font-roboto">Cadet</h3>*/}
					{/*		<p className="mt-1 text-xs text-white text-opacity-40">Completed 1 lecture</p>*/}
					{/*	</div>*/}
					{/*</div>*/}
					{/*<div>*/}
					{/*	<div className="w-12 flex justify-center">*/}
					{/*		<div className="h-[50px] w-1 bg-gray-300">*/}
					{/*			<div className="w-full h-[100%] bg-[#087c30]" />*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*	<div className="flex items-center gap-4">*/}
					{/*		<div className="w-12 h-12 relative rounded-full bg-[#891010] overflow-hidden">*/}
					{/*			<Image className="object-cover w-full h-full" src={explorer} alt="Space Cadet"/>*/}
					{/*			<TickItem />*/}
					{/*		</div>*/}
					{/*		<div className="opacity-40">*/}
					{/*			<h3 className="text-white font-semibold font-roboto">Explorer</h3>*/}
					{/*			<p className="mt-1 text-xs text-white text-opacity-40">Completed 15 lectures</p>*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*</div>*/}
					{/*<div>*/}
					{/*	<div className="w-12 flex justify-center">*/}
					{/*		<div className="h-[50px] w-1 bg-gray-300">*/}
					{/*			<div className="w-full h-[100%] bg-[#087c30]" />*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*	<div className="flex items-center gap-4">*/}
					{/*		<div className="w-12 h-12 relative rounded-full bg-[#891010] overflow-hidden">*/}
					{/*			<Image className="object-cover w-full h-full" src={sergeant} alt="Space Cadet"/>*/}
					{/*			<TickItem />*/}
					{/*		</div>*/}
					{/*		<div className="opacity-40">*/}
					{/*			<h3 className="text-white font-semibold font-roboto">Sergeant</h3>*/}
					{/*			<p className="mt-1 text-xs text-white text-opacity-40">Completed 50 lectures</p>*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*</div>*/}
					{/*<div>*/}
					{/*	<div className="w-12 flex justify-center">*/}
					{/*		<div className="h-[50px] w-1 bg-gray-300">*/}
					{/*			<div className="w-full h-[50%] bg-[#087c30]" />*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*	<div className="flex items-center gap-4">*/}
					{/*		<div className="w-12 h-12 relative rounded-full bg-[#891010] overflow-hidden green-shadow-anim">*/}
					{/*			<Image className="object-cover w-full h-full" src={lieutenant} alt="Space Cadet"/>*/}
					{/*		</div>*/}
					{/*		<div>*/}
					{/*			<h3 className="text-[#16ad4a] font-semibold font-roboto">Lieutenant</h3>*/}
					{/*			<p className="mt-1 text-xs text-white text-opacity-40">Watch 30 more lectures</p>*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*</div>*/}
					{/*<div>*/}
					{/*	<div className="w-12 flex justify-center">*/}
					{/*		<div className="h-[50px] w-1 bg-gray-300">*/}
					{/*			<div className="w-full h-[0%] bg-[#087c30]" />*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*	<div className="flex items-center gap-4">*/}
					{/*		<div className="w-12 h-12 relative rounded-full bg-[#891010] overflow-hidden green-shadow-anim">*/}
					{/*			<Image className="object-cover w-full h-full" src={captain} alt="Space Cadet"/>*/}
					{/*		</div>*/}
					{/*		<div>*/}
					{/*			<h3 className="text-white font-semibold font-roboto">Captain</h3>*/}
					{/*			<p className="mt-1 text-xs text-white text-opacity-40">Watch 380 more lectures</p>*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*</div>*/}
				</div>
				{/*<Image className="absolute top-[70%] left-[50%] animate-float" style={{zoom: 0.2}} src={Stars1} alt={'stars'} />*/}
				{/*<Image className={"stars-bg stars-bg-2"} src={Stars2} alt={'stars'} />*/}
				{/*<Image className={"stars-bg stars-bg-3"} src={Stars3} alt={'stars'} />*/}
				{/*<Image className={"stars-bg stars-bg-4"} src={Stars4} alt={'stars'} />*/}
				{/*<div className="top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 w-[90vw] h-[90vh] max-w-[500px] max-h-[750px] bg-white dark:bg-black">*/}
				{/*</div>*/}
			</Modal>
			{newBadge && <div className="fixed top-0 left-0 w-screen h-screen flex flex-col justify-center bg-black bg-opacity-30 backdrop-blur items-center" style={{zIndex: 99999}}>
        <Confetti
          width={width}
					// height={height - 58}
          confettiSource={{x: width / 2 - 10, y: height / 2, w: 20, h: 0}}
          tweenDuration={300}
          run={true}
          recycle={false}
          numberOfPieces={200}
          wind={0}
          gravity={0.05}
          style={{zIndex: 999}}
          onConfettiComplete={() => {
						setNewBadge(null);
						badgesRef.current?.newBadgeAnimationDone();
					}}
        />
        <div className="cursor-pointer w-24 h-24 border bg-[#891010] rounded-full relative overflow-hidden"
             style={{zIndex: 1001}}>
          <Image className="object-cover w-full h-full" src={newBadge.icon} alt="Space Cadet"/>
        </div>
        <p className="badge-congratulations-title text-3xl mt-3 font-bold text-black dark:text-white" style={{zIndex: 1001}}>Promoted to {newBadge.name}</p>
      </div>}
		</>
	);
};

export default UserBadgesModal;
