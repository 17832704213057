import Axios from '../helpers/pustack-axios';
import {firebaseAPiKey} from '../helpers';
import {castIndianTime} from '../helpers/getIndianTime';
import {formatDateDoc} from './livesessions/sessions';

export const generateInvitationLink = async (uid) => {
	return await Axios.post(
			`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseAPiKey}`,
			{
				dynamicLinkInfo: {
					domainUriPrefix: "https://pustack.page.link",
					link: `https://pustack.com/referrals/?q=${uid}`,
					androidInfo: {
						androidPackageName: "com.pustack.android.pustack",
						androidMinPackageVersionCode: "1",
					},
					socialMetaTagInfo: {
						socialTitle: "PuStack Pro Invitation",
						socialDescription:
							"Get 30 days of PuStack Pro membership when you signup using this link",
						socialImageLink: "https://www.pustack.com/facebook.png",
					},
					navigationInfo: {
						enableForcedRedirect: true,
					},
				},
			}
		)
		.then((res) => res?.data)
		.catch((err) => err);
};



export const refreshNotificationTokens = async (uid) => {
	try {
		// await Axios.post('https://us-central1-avian-display-193502.cloudfunctions.net/' + 'refreshNotificationSubscriptionList', {
		// 	uid
		// });
		console.log('Notification token refreshed');
	} catch (e) {
		console.log('Notification token is not refreshed - ', e.message);
	}
}

export const updateActiveUserList = async (_uid) => {
	let uid = _uid;
	const ist = await castIndianTime(true);
	// uid = 'this_is_my_uid'
	const lastUpdatedActiveUserList = localStorage.getItem('active_user_updated_on');
	if(lastUpdatedActiveUserList === ist.toLocaleDateString()) {
		console.log('Active user list is already updated');
		return;
	}

	localStorage.setItem('active_user_updated_on', ist.toLocaleDateString());
	return require('../firebase-config')
		.db
		.collection('user_engagement')
		.doc('interaction_engagement')
		.collection('user_status')
		.doc(formatDateDoc(ist, null, true))
		// .doc('2023_4')
		.collection('days')
		.doc(formatDateDoc(ist))
		// .doc('2023_4_28')
		.set({
			active_user_map: {
				[uid]: {
					active_on: {
						app: {
							app_name: 'pustack_app',
							platform: 'web',
							version: "5.0.1",
						},
						timestamp: ist.serverISOString
					}
				}
			}
		}, {merge: true});
}
