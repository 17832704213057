import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import Stories from "react-insta-stories";
import Close from "@material-ui/icons/Close";
import {SubjectModalContext, ThemeContext, UserContext} from "../../../context";
import {ChevronRight} from "@material-ui/icons";
import {ModalContext} from "../../../context/global/ModalContext";
import SwipeableViews from "react-swipeable-views";
import {storyEventNames} from '../../../database/story';
import {useMediaQuery} from "react-responsive";
import StoryCarousel from "./StoryCarousel";

const accessTokenRonak = 'eb3aa30f683094b5e51d077a9b8bbff5';

export const SpinnerSVG = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={44}
		height={44}
		stroke="#fff"
		{...props}
	>
		<g fill="none" fillRule="evenodd" strokeWidth={2}>
			<circle cx={22} cy={22} r={1}>
				<animate
					attributeName="r"
					begin="0s"
					calcMode="spline"
					dur="1.8s"
					keySplines="0.165, 0.84, 0.44, 1"
					keyTimes="0; 1"
					repeatCount="indefinite"
					values="1; 20"
				/>
				<animate
					attributeName="stroke-opacity"
					begin="0s"
					calcMode="spline"
					dur="1.8s"
					keySplines="0.3, 0.61, 0.355, 1"
					keyTimes="0; 1"
					repeatCount="indefinite"
					values="1; 0"
				/>
			</circle>
			<circle cx={22} cy={22} r={1}>
				<animate
					attributeName="r"
					begin="-0.9s"
					calcMode="spline"
					dur="1.8s"
					keySplines="0.165, 0.84, 0.44, 1"
					keyTimes="0; 1"
					repeatCount="indefinite"
					values="1; 20"
				/>
				<animate
					attributeName="stroke-opacity"
					begin="-0.9s"
					calcMode="spline"
					dur="1.8s"
					keySplines="0.3, 0.61, 0.355, 1"
					keyTimes="0; 1"
					repeatCount="indefinite"
					values="1; 0"
				/>
			</circle>
		</g>
	</svg>
)


const tester = (story) => {
	return {
		// Use this renderer only when the story type is video
		condition: story.type === 'video',
		priority: 3,
	};
};

const stories = [
	{
		url: "https://player.vimeo.com/progressive_redirect/playback/898746993/rendition/240p/file.mp4?loc=external&oauth2_token_id=1574768840&signature=aa659ef886fa2f4ec369df38e710ed4a1ed4c63d5be1b40259ac937816237546",
		type: 'video',
		preloadResource: true,
		styles: {
			width: '100%',
			height: '100%'
		}
	},
	{
		url: "https://player.vimeo.com/progressive_redirect/playback/899747147/container/2ae89eb8-8350-4daa-bef9-547de5f17e5c/0f5d0a76-f60b0189?expires=1704618860&loc=external&oauth2_token_id=1574768840&signature=96953ec4cbe2b40c6a7cbcaf4226692e92504faf89b8766778bb83110fba68f5",
		type: 'video',
		preloadResource: true,
		styles: {
			width: '100%',
			height: '100%'
		}
	}
]

function playSubjectStory(index) {}

function StoryVideoHolder({storyId, storyItems, isVisible, startIndex = 0, playSubjectStory}) {
	// const [currentIndex, setCurrentIndex] = useState(startIndex);
	const [storyVideoIndices, setStoryVideoIndices] = useContext(SubjectModalContext).storyVideoIndices;
	const storyEventRef = useContext(SubjectModalContext).storyEventRef;

	function handleChange(direction) {
		return (object) => {
			const index = object[storyId] ?? 0;
			console.log('Vimeo Debug | - ', storyItems, index);
			const newIndex = index + direction;
			if(newIndex < 0) {
				playSubjectStory(-1)
				return {...object, [storyId]: index};
			}
			if(newIndex > storyItems.length - 1) {
				playSubjectStory(1);
				return {...object, [storyId]: index};
			}

			return {...object, [storyId]: newIndex};
		}
	}

	useEffect(() => {
		if(isVisible) {
			storyEventRef.current.dispatchEvent(storyEventNames.storyViewed, {storyId: storyId});
		}
	}, [storyId, isVisible])

	return isVisible ? (
		<Stories
			preloadCount={3}
			// keyboardNavigation
			defaultInterval={8000}
			// renderers={[{
			// renderer: StoryRenderer.Renderer,
			// tester: StoryRenderer.Tester
			// }]}
			// loop
			stories={storyItems}
			width={'100%'}
			height={'100%'}
			storyStyles={{color: 'red', width: '100%', height: '100%'}}
			storyContainerStyles={{color: 'black', background: 'transparent'}}
			storyInnerContainerStyles={{
				color: 'white',
				width: '100%',
				height: '100%',
				display: 'flex',
				alignItems: 'stretch',
				gridTemplateColumns: '1fr',
				justifyItems: 'center',
				justifyContent: 'center'
			}}
			onAllStoriesEnd={() => {
				console.log('All Stories end');
			}}
			currentIndex={storyVideoIndices[storyId]}
			onNext={(cur) => {
				console.log('Vimeo Debug | onNext - ', cur);
				setStoryVideoIndices(handleChange(1));
			}}
			onPrevious={(cur) => {
				console.log('Vimeo Debug | onPrevious - ', cur);
				setStoryVideoIndices(handleChange(-1));
			}}
		/>
	) : null;
}

const storyConfig = {
	grades: [
		{id: 'class_9', value: 'class_9', label: 'Class 9'},
		{id: 'class_10', value: 'class_10', label: 'Class 10'},
	],
	subjects: [
		{id: 'maths', value: 'maths', label: 'Maths', folderId: '18980897'},
		{id: 'science', value: 'science', label: 'Science', folderId: '18980898'},
		{id: 'sst', value: 'sst', label: 'SST', folderId: '18980899'},
		{id: 'english', value: 'english', label: 'English', folderId: '18980901'},
	]
}

export default function StoryModal() {
	const [showStoryModal, setShowStoryModal] = useContext(SubjectModalContext).showStoryModal;
	const [selectedStoryIndex, setSelectedStoryIndex] = useContext(SubjectModalContext).selectedStoryIndex;
	const [stories] = useContext(SubjectModalContext).stories;
	// const stories = useMemo(() => {
	//   return mockStories;
	// }, []);
	const [modalData, setModalData] = useContext(ModalContext).state;
	const isSmallScreen = useMediaQuery({query: '(max-width: 500px)'});

	function playSubjectStory(direction) {
		if(!stories) return;
		setSelectedStoryIndex(c => {
			const newIndex = c + direction;
			if(newIndex < 0 || newIndex > stories?.length - 1) {
				handleClose();
				return c;
			}
			return newIndex;
		});
	}

	function handleClose() {
		setModalData({
			open: false,
			Children: null
		})
		setShowStoryModal(false);
	}


	return showStoryModal ? (
		<div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-90 flex justify-center items-center" style={{zIndex: 300}}>
			<div className="absolute top-5 right-5  cursor-pointer bg-gray-200 rounded-full p-1" onClick={handleClose} style={{zIndex: 200}}>
				<Close />
			</div>
			<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[95vw] h-[92vh] md:w-[100vw] md:h-[100vh] flex justify-center items-center bg-black bg-opacity-40">
				<>
					<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
						<SpinnerSVG/>
					</div>
					{isSmallScreen ? (
						<SwipeableViews
							axis={"x"}
							scrolling={"false"}
							index={selectedStoryIndex}
							style={{outline: "none", width: "100%", height: '100%'}}
							containerStyle={{
								width: '100%',
								height: '100%',
								transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s'
							}}
							ignoreNativeScroll={true}
							disabled={true}
						>
							{stories.map((story, ind) => (
								<StoryVideoHolder key={story.id} isVisible={selectedStoryIndex === ind} storyId={story.id} storyItems={story.items ?? []} playSubjectStory={playSubjectStory}/>
							))}
						</SwipeableViews>
					) : <StoryCarousel handleClose={handleClose}/>}
				</>
			</div>
		</div>
	) : null
}
