import React, {useState, useRef, useCallback, createContext, useEffect, useMemo} from "react";
import {useRouter} from "next/router";
import {refreshNotificationTokens, updateActiveUserList} from "../../database";
import {newMsgAudio} from "../../public/assets";
import useSnackbar from "../../hooks/snackbar/useSnackbar";
import {Transform} from "../../helpers/utils";
import axios from "../../helpers/pustack-axios";
import {setSafariToken} from "../../helpers";
import {useMediaQuery} from "react-responsive";
import {Badges} from "../../components/global/UserBadgesModal";

export const UserContext = createContext();


class Avatar {
  images = {};

  genUniqueColor(name) {
    const hRange = [0, 360];
    const sRange = [50, 75];
    const lRange = [25, 60];

    const getHashOfString = (str = '') => {
      if(!str) return '';
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      hash = Math.abs(hash);
      return hash;
    };
    const normalizeHash = (hash, min, max) => {
      return Math.floor((hash % (max - min)) + min);
    };

    const generateHSL = () => {
      const hash = getHashOfString(name);
      const h = normalizeHash(hash, hRange[0], hRange[1]);
      const s = normalizeHash(hash, sRange[0], sRange[1]);
      const l = normalizeHash(hash, lRange[0], lRange[1]);
      return [h, s, l];
    };

    const HSLtoString = (hsl) => {
      return `hsl(${hsl[0]}deg, ${hsl[1]}%, ${hsl[2]}%)`;
    };

    return HSLtoString(generateHSL());
  }
  gen(name = "S", uid = 'random-uid') {
    const str = this.genUniqueColor(uid);
    const svg = `
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 64 64" version="1.1"><rect fill="${str}" cx="32" width="64" height="64" cy="32" r="32"/><text x="50%" y="50%" style="color: #fff; line-height: 1;font-family: 'Roboto', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;" alignment-baseline="middle" text-anchor="middle" font-size="32" font-weight="400" dy=".1em" dominant-baseline="middle" fill="#fff">${name[0]}</text></svg>
    `;
    const blob = new Blob([svg], {type: 'image/svg+xml'});
    return URL.createObjectURL(blob);
  }
  get(name = "S", uid) {
    if(!uid) return this.gen(name, uid);
    // if(this.images[uid]) {
    //   return this.images[uid];
    // }
    this.images[uid] = this.gen(name, uid);
    return this.images[uid];
  }
}

const avatarObj = new Avatar();

export function getAvatarImage(name, uid) {
  return avatarObj.get(name, uid)
}

export const UserContextProvider = ({
  userHasNoGrade: _userHasNoGrade,
  isUserProTier: _isUserProTier,
  user,
  setUser,
  openNameModal,
  setOpenNameModal,
  snackbarData: _snackbarData,
  pushyData: _pushyData,
  blazeCallAlert: _blazeCallAlert,
  isInstructor: _isInstructor,
  isExternal: _isExternal,
  unreadCareMsgCount: _unreadCareMsgCount,
  closeInstallApp: _closeInstallApp,
  isSliderOpen,
  setIsSliderOpen,
  openGuestBottomDrawer,
  setOpenGuestBottomDrawer,
  clientDisconnectRef,
  userDisconnectRef,
  userPathRef,
  clientPathRef,
  ...props
}) => {
  // const [user, setUser] = useState(_user);
  const [openMenu, setOpenMenu] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [pushyData, setPushyData] = useState(_pushyData);
  const [isExternal, setIsExternal] = useState(_isExternal);
  const [hasSessions, setHasSessions] = useState(false);
  const [_isInstructor1, setIsInstructor] = useState(_isInstructor);
  const [closeCarePage, setCloseCarePage] = useState(true);
  const [openSearchBox, setOpenSearchBox] = useState(false);
  const [showBlazeMain, setShowBlazeMain] = useState(false);
  const [__isUserProTier, setIsUserProTier] = useState(_isUserProTier);
  const [blazeCallAlert, setBlazeCallAlert] = useState(_blazeCallAlert);
  const [openPustackCare, setOpenPuStackCare] = useState(false);
  const [unreadAnswerCount, setUnreadAnswerCount] = useState(0);
  const [closeInstallApp, setCloseInstallApp] = useState(_closeInstallApp);
  const [openMenuSettings, setOpenMenuSettings] = useState(false);
  const [unreadCareMsgCount, setUnreadCareMsgCount] = useState(_unreadCareMsgCount);
  const [sectionHighlighted, setSectionHighlighted] = useState(0);
  const [openBlazeCallModal, setOpenBlazeCallModal] = useState(false);
  const [referrerId, setReferrerId] = useState(null);
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [hasRatedBlazeSession, setHasRatedBlazeSession] = useState(false);
  const [bounceBlazeIcon, setBounceBlazeIcon] = useState(false);
  const [userHasNoGrade, setUserHasNoGrade] = useState(_userHasNoGrade);
  const [acceptedBlazeSessionList, setAcceptedBlazeSessionList] = useState(null);
  const [userVisitedGradeScreen, setUserVisitedGradeScreen] = useState(() => {
    return Boolean(window.localStorage.getItem('userVisitedGradeScreen')) ? 'isThere': null;
  });
  const [blazeUnreadCount, setBlazeUnreadCount] = useState({});
  const isSmallScreen = useMediaQuery({ query: "(max-width: 500px)" });
  const [newMessage, setNewMessage] = useState(false);
  const [openBadgeModal, setOpenBadgeModal] = useState(false);
  const [currentBadge, setCurrentBadge] = useState(null);
  const [newBadge, setNewBadge] = useState(null);

  const router = useRouter();
  const {show} = useSnackbar();


  const sessionEngagementRef = useRef({});
  const lectureEngagementRef = useRef({});
  const userIdRef = useRef({});
  const gradeRef = useRef(null);
  const userBadgesRef = useRef(null);

  const isUserProTier = true;

  const isInstructor = useMemo(() => {
    if(!user || user.is_guest) return false;
    return _isInstructor1;
  }, [_isInstructor1, user])

  useEffect(() => {
    function run() {
      if(!window || !navigator.serviceWorker) return;
      const url = window.location.origin + '/web-worker.js'
      navigator.serviceWorker.getRegistrations().then(registrations => {
        const myRegistration = registrations.find(c => c.active.scriptURL === url);
        if(!myRegistration) {
          return reg();
        }

        console.log('myRegistration - ', myRegistration);

        // myRegistration.showNotification('My notification title', {
        // 	body: 'Notified by Saurabh',
        // 	icon: 'http://image.ibb.co/frY0Fd/tmlogo.png'
        // })

        const publicKey = 'BCdzu_KxVX85f1OfEDA1CQBJBSlPIBm2NEGYm029MbUJslLA7TJK3lvyxpRDocBz9UrcYeYash3K3r736uS4dww';
        myRegistration.pushManager.subscribe({
          applicationServerKey: publicKey,
          userVisibleOnly: true
        }).then(subscription => {
          console.log('subscription 01 - ', subscription);
        })

        myRegistration.pushManager.getSubscription()
          .then(async (subscription) => {
            console.log('subscription - ', subscription);

            if(subscription && userIdRef.current) {
              await setSafariToken(userIdRef.current, subscription);
              window.removeEventListener('click', run);
            }
          })
      })
      async function reg() {

        navigator.serviceWorker.register(url, {
          scope: '/'
        }).then(async (register) => {
          var serviceWorker;
          if (register.installing) {
            serviceWorker = register.installing;
            // console.log('Service worker installing');
          } else if (register.waiting) {
            serviceWorker = register.waiting;
            // console.log('Service worker installed & waiting');
          } else if (register.active) {
            serviceWorker = register.active;
            const publicKey = 'BCdzu_KxVX85f1OfEDA1CQBJBSlPIBm2NEGYm029MbUJslLA7TJK3lvyxpRDocBz9UrcYeYash3K3r736uS4dww';

            const subscription = await register.pushManager.subscribe({
              applicationServerKey: publicKey
            });

            if(subscription && userIdRef.current) {
              await setSafariToken(userIdRef.current, subscription);
              document.removeEventListener('click', run);
            }

            console.log('subscription - ', subscription);
            // console.log('Service worker active');
          }

          if (serviceWorker) {
            console.log("sw current state", serviceWorker.state);
            if (serviceWorker.state == "activated") {
              //If push subscription wasnt done yet have to do here
              console.log("sw already activated - Do watever needed here");
            }
            serviceWorker.addEventListener("statechange", async function(e) {
              console.log("sw statechange : ", e.target.state);
              if (e.target.state == "activated") {
                // use pushManger for subscribing here.
                console.log("Just now activated. now we can subscribe for push notification")
                const publicKey = 'BCdzu_KxVX85f1OfEDA1CQBJBSlPIBm2NEGYm029MbUJslLA7TJK3lvyxpRDocBz9UrcYeYash3K3r736uS4dww';

                const subscription = await register.pushManager.subscribe({
                  applicationServerKey: publicKey,
                  userVisibleOnly: true
                });

                if(subscription && userIdRef.current) {
                  await setSafariToken(userIdRef.current, subscription);
                  document.removeEventListener('click', run);
                }
                console.log('subscription - ', subscription);
                // subscribeForPushNotification(reg);
              }
            });
          }
        }).catch(e => {
          console.log('e - ', e);
        })
      }
    }

    document.addEventListener('click', run);

    return () => {
      document.removeEventListener('click', run);
    }
  }, []);

  useEffect(() => {
    if(user?.uid) {
      userIdRef.current = user.uid;
      gradeRef.current = user?.grade;
      updateActiveUserList(user?.uid);
    }
  }, [user])

  useEffect(() => {
    setIsUserProTier(_isUserProTier);
  }, [_isUserProTier])

  useEffect(() => {
    setUnreadCareMsgCount(_unreadCareMsgCount)
  }, [_unreadCareMsgCount]);

  useEffect(() => {
    setCloseInstallApp(_closeInstallApp)
  }, [_closeInstallApp]);

  useEffect(() => {
    setBlazeCallAlert(_blazeCallAlert)
  }, [_blazeCallAlert]);

  useEffect(() => {
    setIsExternal(_isExternal)
  }, [_isExternal]);

  useEffect(() => {
    setPushyData(_pushyData)
  }, [_pushyData]);

  useEffect(() => {
    if(_snackbarData) show(_snackbarData)
  }, [show, _snackbarData]);

  function listenToAcceptedSessions(sessions, unreadSessions, userID) {
    if(!sessions || !unreadSessions) return;
    const keys = Object.keys(unreadSessions);
    const expiredKeys = keys.filter(key => {
      const inSessionList = sessions.some(c => c.id === key);
      return !inSessionList;
    });
    expiredKeys.forEach(key => {
      if(unreadSessions[key] > 0) {
        setBlazeUnreadCount(c => ({...c, [key]: 0}));
        require('../../firebase-config').rdb.ref('/users/' + userID + '/pustack_app/unread_blaze_message_count/' + key).set(0);
      }
    })
  }

  useEffect(() => {
    if(!user?.uid) return;
    listenToAcceptedSessions(acceptedBlazeSessionList, blazeUnreadCount, user?.uid)
  }, [acceptedBlazeSessionList, user, blazeUnreadCount])

  const newMessageRef = useRef(false);
  useEffect(() => {
    if(!user?.uid) return;
    const blazeTotalMessagesCount = (userID) => {

      let prevValues = {};
      require('../../firebase-config').rdb.ref('/users/' + userID + '/pustack_app/unread_blaze_message_count').on('value', (snapshot) => {
        for(let i in snapshot.val()) {
          let val = snapshot.val()[i];
          const prevVal = prevValues[i];
          if(val > 0 && prevVal !== val) {
            console.log('val - ', val, prevVal, i);
            setNewMessage(i);
            setTimeout(() => setNewMessage(false), 100);
          }
        }
        prevValues = snapshot.val() ?? {};
        if(snapshot.val()) setBlazeUnreadCount(snapshot.val());
      })
    };
    blazeTotalMessagesCount(user?.uid);
  }, [user?.uid]);

  useEffect(() => {
    if(newMessage && window.location.pathname !== ('/blaze/chat/' + newMessage)) {
      let audio = new Audio(newMsgAudio);
      audio.play();
      setNewMessage(false);
    }
  }, [newMessage])

  const openMobileSearch = router.query.search;

  const setOpenMobileSearch = useCallback((isVisible) => {
    if(isVisible) return router.push({
      pathname: router.pathname,
      query: {
        ...router.query,
        search: true
      }
    });
    if(!router.query.search) return;
    const _query = {
      ...router.query,
      search: undefined
    };

    delete _query.search;

    return router.push({
      pathname: router.pathname,
      query: _query
    });
  }, [router]);

  const _setIsSliderOpen = useCallback((open) => {
    isSmallScreen && open !== 'sign-in' ? setOpenGuestBottomDrawer(open) : setIsSliderOpen(open);

    console.log('open - ', open);

    if(isSmallScreen ? open === 'sign-in' : open) {
      isSmallScreen ? setOpenMobileSearch(false) : setOpenSearchBox(false);
    }
  }, [isSmallScreen, setIsSliderOpen, setOpenGuestBottomDrawer, setOpenMobileSearch]);

  useEffect(() => {
    function run (e) {
      if(e.target.visibilityState === 'visible') return;

      const lectureMap = Transform.shapeLectureMap(lectureEngagementRef.current);
      const _lectureMap = Transform.shapeLectureMap(sessionEngagementRef.current);

      const body = {
        uid: userIdRef.current,
        liveSessionTimeSpent: _lectureMap.total_time_spent,
        lectureMap,
        context: {
          auth: true
        }
      };

      console.log('body - ', body)
      const url = 'https://asia-east1-avian-display-193502.cloudfunctions.net/updateLectureEngagementData';

      // axios.post(url, JSON.stringify(body));

      fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        },
        keepalive: true
      });

      lectureEngagementRef.current = {};
      sessionEngagementRef.current = {};
    }

    // window.addEventListener('unload', run);
    window.addEventListener('visibilitychange', run);

    return () => {
      // run();
      // window.removeEventListener('unload', run);
      window.removeEventListener('visibilitychange', run);
    }
  }, []);

  useEffect(() => {
    if(userVisitedGradeScreen !== 'isThere' && userVisitedGradeScreen) {
      window.localStorage.setItem('userVisitedGradeScreen', new Date().toISOString());
    }
  }, [userVisitedGradeScreen])

  return (
    <UserContext.Provider
      value={{
        user: [user, setUser],
        openMenu: [openMenu, setOpenMenu],
        openChat: [openChat, setOpenChat],
        pushyData: [pushyData, setPushyData],
        tier: [isUserProTier, setIsUserProTier],
        isExternal: [isExternal, setIsExternal],
        referrerId: [referrerId, setReferrerId],
        hasSessions: [hasSessions, setHasSessions],
        totalSeconds: [totalSeconds, setTotalSeconds],
        isInstructor: [isInstructor, setIsInstructor],
        openSearchBox: [openSearchBox, setOpenSearchBox],
        closeCarePage: [closeCarePage, setCloseCarePage],
        showBlazeMain: [showBlazeMain, setShowBlazeMain],
        blazeCallAlert: [blazeCallAlert, setBlazeCallAlert],
        closeInstallApp: [closeInstallApp, setCloseInstallApp],
        openPustackCare: [openPustackCare, setOpenPuStackCare],
        openMobileSearch: [openMobileSearch, setOpenMobileSearch],
        openMenuSettings: [openMenuSettings, setOpenMenuSettings],
        unreadAnswerCount: [unreadAnswerCount, setUnreadAnswerCount],
        unreadCareMsgCount: [unreadCareMsgCount, setUnreadCareMsgCount],
        userHasNoGrade: [userHasNoGrade, setUserHasNoGrade],
        sectionHighlighted: [sectionHighlighted, setSectionHighlighted],
        openBlazeCallModal: [openBlazeCallModal, setOpenBlazeCallModal],
        hasRatedBlazeSession: [hasRatedBlazeSession, setHasRatedBlazeSession],
        bounceBlazeIcon: [bounceBlazeIcon, setBounceBlazeIcon],
        acceptedBlazeSessionList: [acceptedBlazeSessionList, setAcceptedBlazeSessionList],
        blazeUnreadCount: [blazeUnreadCount, setBlazeUnreadCount],
        openNameModal: [openNameModal, setOpenNameModal],
        userVisitedGradeScreen: [userVisitedGradeScreen, setUserVisitedGradeScreen],
        isSliderOpen: [isSliderOpen, _setIsSliderOpen],
        openGuestBottomDrawer: [openGuestBottomDrawer, setOpenGuestBottomDrawer],
        openBadgeModal: [openBadgeModal, setOpenBadgeModal],
        currentBadge: [currentBadge, setCurrentBadge],
        newBadge: [newBadge, setNewBadge],
        sessionEngagementRef,
        lectureEngagementRef,
        clientDisconnectRef,
        userDisconnectRef,
        userPathRef,
        clientPathRef,
        gradeRef,
        userBadgesRef
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
