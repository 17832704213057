import {assetForSubjects} from '../../../database/story';
import {useCallback, useContext, useEffect} from 'react';
import {SubjectModalContext} from '../../../context'
import useMediaQuery from 'react-responsive';
import {Add} from '@material-ui/icons';
import {StoryIcon} from './StoryIcon';


export function fetchAssetsOfChapter(str) {
	const fileName = str.split('/').reverse()[0];
	const subjectName = fileName.split('.')[0];

	return assetForSubjects[subjectName];
}

export const STORY_STATUS = {
	'LOADING': 'loading',
	'IS_TRANSCODING': 'isTranscoding',
	'IS_UPLOADING': 'isUploading',
	'LOADED': 'loaded',
}

function StoryItemShimmer() {
	return (
		<div className="flex-1 flex flex-col animate-pulse items-center justify-center cursor-pointer">
			<div className="app-shimmer bg-black md:bg-white dark:bg-white bg-opacity-10 aspect-square w-full p-0 rounded-full"/>
			<span className="app-shimmer bg-black md:bg-white dark:bg-white bg-opacity-10 text-xs mt-1 rounded pointer-events-none">English</span>
		</div>
	)
}

export const StoryListItem = () => {
	const [stories] = useContext(SubjectModalContext).stories;
	const [, setSelectedStoryIndex] = useContext(SubjectModalContext).selectedStoryIndex;
	const [, setShowStoryModal] = useContext(SubjectModalContext).showStoryModal;
	const [, setCursor] = useContext(SubjectModalContext).storyCursor;
	const isSmallScreen = useMediaQuery({ query: "(max-width: 500px)" });

	useEffect(() => {
		console.log('Bunny CDN Debug | stories - ', stories);
	}, [stories])

	const handleStoryItemClick = useCallback(async (storyId) => {
		// const items = await fetchStoryItems(storyId);
		const itemIndex = stories.findIndex(c => c.id === storyId);
		setSelectedStoryIndex(() => {
			setShowStoryModal(true);
			return itemIndex;
		});
		setCursor(itemIndex);
	}, [setCursor, setSelectedStoryIndex, setShowStoryModal, stories]);

	return (
		<>
			{/*{!isSmallScreen && <label htmlFor="upload-story" className="flex flex-col items-center justify-center cursor-pointer">*/}
      {/*  <input id="upload-story" hidden type="file" accept={"video/mp4,video/x-m4v,video/*"} className="w-full h-full"*/}
      {/*         onChange={e => {*/}
			{/*		       setUploadStoryFile(e.target.files[0]);*/}
			{/*		       setShowStoryModal(true);*/}
			{/*	       }}/>*/}
      {/*  <div className="bg-[#1a72d6] flex justify-center items-center w-[90%] rounded cursor-pointer h-8">*/}
      {/*    <Add style={{fontSize: '18px'}}/>*/}
      {/*    <span className="text-sm text-white text-opacity-80">Create Story</span>*/}
      {/*  </div>*/}
      {/*</label>}*/}
			<div className="w-full flex items-center gap-2 justify-evenly px-2 overflow-hidden py-3">
			{/*	{isSmallScreen && <label htmlFor="upload-story"*/}
      {/*                           className="flex flex-col items-center justify-center cursor-pointer">*/}
      {/*    <input id="upload-story" hidden type="file" accept={"video/mp4,video/x-m4v,video/*"} className="w-full h-full"*/}
      {/*           onChange={e => {*/}
			{/*			       setUploadStoryFile(e.target.files[0]);*/}
			{/*			       setShowStoryModal(true);*/}
			{/*		       }}/>*/}
      {/*    <div className="bg-gray-200 dark:bg-gray-700 flex justify-center items-center w-[60px] h-[60px] rounded-full cursor-pointer h-8">*/}
      {/*      <Add style={{fontSize: '23px'}} className="text-gray-900 dark:text-white"/>*/}
      {/*    </div>*/}
      {/*    <span className="text-sm text-gray-900 dark:text-white text-opacity-80 mt-1">Upload</span>*/}
      {/*  </label>}*/}
				{stories ?
					stories.filter(c => !c.fetched || c.items?.length > 0).map(story => (
						<StoryIcon
							key={story.id}
							handleClick={handleStoryItemClick}
							story={story}
							big={false}
						/>
					)) :
					[1, 2, 3, 4].map(story => <StoryItemShimmer key={story}/>)
				}
			</div>
		</>
	)
}
