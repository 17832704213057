
interface Config extends Object {
	headers: {}
}

export default class Axios {

	static async postWithText(url: string, data?: any, config: Config = {headers: {}}) {
		try {
			const res = await fetch(url, {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					...config.headers
				},
				body: JSON.stringify(data)
			})
			const d = await res.text();
			return {data: d};
		} catch (e) {
			console.log('Error in pustack-axios POST request - ', e);
			return e;
		}
	}

	static async post(url: string, data?: any, config: Config = {headers: {}}) {
		try {
			const res = await fetch(url, {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					...config.headers
				},
				body: JSON.stringify(data)
			})
			const d = await res.json();
			return {data: d};
		} catch (e) {
			console.log('Error in pustack-axios POST request - ', e);
			return e;
		}
	}

	static async get(url, config: Config = {headers: {}}) {
		try {
			const res = await fetch(url, {
				method: "GET",
				headers: {
					'Content-Type': 'application/json',
					...config.headers
				}
			})
			const d = await res.json();
			return {data: d};
		} catch (e) {
			console.log('Error in pustack-axios GET request - ', e);
			return e;
		}
	}
}
