import {z} from 'zod';
import biology from '../../public/assets/subjects/biology.svg';
import biologyBG from '../../public/assets/subjects/biologyBg.svg';
import chemistry from '../../public/assets/subjects/chemistry.svg';
import chemistryBG from '../../public/assets/subjects/chemistryBg.svg';
import civics from '../../public/assets/subjects/civics.svg';
import civicsBG from '../../public/assets/subjects/civicsBg.svg';
import economics from '../../public/assets/subjects/economics.svg';
import economicsBG from '../../public/assets/subjects/economicsBg.svg';
import english from '../../public/assets/subjects/english.svg';
import geography from '../../public/assets/subjects/geography.svg';
import geographyBG from '../../public/assets/subjects/geographyBg.svg';
import grammar from '../../public/assets/subjects/grammar.svg';
import grammarBG from '../../public/assets/subjects/grammarBg.svg';
import history from '../../public/assets/subjects/history.svg';
import historyBG from '../../public/assets/subjects/historyBg.svg';
import literature from '../../public/assets/subjects/literature.svg';
import literatureBG from '../../public/assets/subjects/literatureBg.svg';
import maths from '../../public/assets/subjects/maths.svg';
import mathsBG from '../../public/assets/subjects/mathsBg.svg';
import physics from '../../public/assets/subjects/physics.svg';
import physicsBG from '../../public/assets/subjects/physicsBg.svg';
import reading from '../../public/assets/subjects/reading.svg';
import readingBG from '../../public/assets/subjects/readingBg.svg';
import writing from '../../public/assets/subjects/writing.svg';
import writingBG from '../../public/assets/subjects/writingBg.svg';


export const bunnyCdnConfigSchema = z.object({
	api_key: z.string(),
	pull_zone: z.string(),
	host_name: z.string(),
	library_id: z.string(),
});

export const getClassName = (grade) => {
	const splitted = grade.split("_");

	return (
		splitted[0].charAt(0).toUpperCase() +
		splitted[0].slice(1) +
		" " +
		splitted[1]
	);
};

export const storyConfigSchema = z.object({
	bunny_cdn: bunnyCdnConfigSchema
});

export async function getBunnyCDNConfig() {
	const storyConfig = await require('../../firebase-config').db.doc('admin/story_config').get();

	const storyConfigData = storyConfigSchema.safeParse(storyConfig.data());

	if(!storyConfigData.success) {
		console.error('Story config data is not valid', storyConfigData.error);
		return null;
	}

	return storyConfigData.data.bunny_cdn;
}

export const storyEventNames = {
	uploadStoryProgress: 'uploadStoryProgress',
	transcoding: 'transcoding',
	storyViewed: 'storyViewed',
};

export const assetForSubjects = {
	maths: {
		bgImage: mathsBG,
		image: maths
	},
	english: {
		bgImage: literatureBG,
		image: english
	},
	physics: {
		bgImage: physicsBG,
		image: physics
	},
	chemistry: {
		bgImage: chemistryBG,
		image: chemistry
	},
	biology: {
		bgImage: biologyBG,
		image: biology
	},
	history: {
		bgImage: historyBG,
		image: history
	},
	geography: {
		bgImage: geographyBG,
		image: geography
	},
	economics: {
		bgImage: economicsBG,
		image: economics
	},
	civics: {
		bgImage: civicsBG,
		image: civics
	},
	grammar: {
		bgImage: grammarBG,
		image: grammar
	},
	literature: {
		bgImage: literatureBG,
		image: literature
	},
	reading: {
		bgImage: readingBG,
		image: reading
	},
	writing: {
		bgImage: writingBG,
		image: writing
	},
}

export async function fetchStoryItems(storyId, userGrade) {
	const stories = [];
	const bunnyCDNConfig = await getBunnyCDNConfig();
	console.log('Bunny CDN Debug | storyItems - ', bunnyCDNConfig);
	if(!bunnyCDNConfig) return [];
	await require('../../firebase-config').db.collection('stories/' + storyId + '/items').get().then(async (querySnapshot) => {
		for(let i = 0; i < querySnapshot.docs.length; i++) {
			const doc = querySnapshot.docs[i];
			// const url = 'https://api.vimeo.com/videos/' + doc.data().video_id;
			// const response = await axios.get(url, {
			//   headers: {
			//     'Authorization': 'Bearer eb3aa30f683094b5e51d077a9b8bbff5'
			//   }
			// });
			// const _url = response.data.play.progressive.find(c => c.rendition === "720p")?.link ?? response.data.play.progressive[0].link;
			// const _url = `https://video.bunnycdn.com/play/${bunnyCDNConfig.library_id}/${doc.data().video_id}`;
			if(doc.data().grade_id?.includes(userGrade)) {
				const _url = `https://${bunnyCDNConfig.host_name}/${doc.data().video_id}/play_240p.mp4`
				stories.push({
					url: _url,
					type: 'video',
					id: doc.id,
					preloadResource: true,
					isTransCoding: false,
					created_on: doc.data().created_on,
					grade_id: doc.data().grade_id,
					video_id: doc.data().video_id,
				});
			}
		}
		// querySnapshot.forEach((doc) => {
		//   console.log(doc.id, " => ", doc.data());
		//
		// });
	});
	stories.sort((a, b) => new Date(b.created_on).getTime() - new Date(a.created_on).getTime());
	return stories;
}

export async function fetchStories() {
	const stories = [];
	await require('../../firebase-config').db.collection('stories').get().then(async (querySnapshot) => {
		for(let i = 0; i < querySnapshot.docs.length; i++) {
			const doc = querySnapshot.docs[i];
			// const items = await fetchStoryItems(doc.id, i === 0);
			stories.push({
				...(doc.data()),
				fetched: false,
				items: [],
			});
		}
	});

	stories.sort((a, b) => {
		const aLatestItemTime = Math.max(...a.items.map(item => item.created_on));
		const bLatestItemTime = Math.max(...b.items.map(item => item.created_on));

		return bLatestItemTime - aLatestItemTime;
	});

	return stories;
}

export async function fetchBunnyCDNCollections() {
	const config = await getBunnyCDNConfig();
	if(!config) return null;
	const response = await fetch(`https://video.bunnycdn.com/library/${config.library_id}/collections`, {
		method: 'GET',
		headers: {
			'AccessKey': config.api_key,
			"Content-Type": "application/json",
		}
	});
	const data = await response.json();
	return data.items;
}
