import {useEffect, useRef, useState, useCallback} from 'react';
import {useRouter} from 'next/router';
import {defaultPic} from '../public/assets'
import {VAPIDKEY, commonPages, publicPages, removeSafariToken} from "../helpers";
import {castIndianTime} from "../helpers/getIndianTime";
import nookies from 'nookies';
import axios from "../helpers/pustack-axios";
import astronautPic from '../public/assets/onboarding/gulu-dp.svg';
import {isDestructible, omitUndefinedKeys, safeDestructible} from '../helpers/utils';
import firebase from 'firebase/app';


const getCareMessageCount = ({ userId, grade }) => {
  return (require('../firebase-config')).db
    .collection("user_notifications")
    .doc(grade)
    .collection("user_notifications")
    .doc(userId);
};


const userImportantData = (userId) => {
  // .then((doc) => doc.data().tier === "pro");

  return (require('../firebase-config')).db.collection("users").doc(userId);
};

export const updateUserToIndexedDB = (userId) => {
  const openRequest = window.indexedDB.open('pustack-db', 1);
  openRequest.onupgradeneeded = function(e) {
    const db = e.target.result;
    db.createObjectStore("pustack-db-store", {
      "autoIncrement": true
    });
  }
  openRequest.onsuccess = function(e) {
    const instance = e.target.result;

    const transaction = instance.transaction('pustack-db-store', 'readwrite');
    const objectStore = transaction.objectStore('pustack-db-store');
    const getKeyRequest = objectStore.put(userId, 'user');
    getKeyRequest.onsuccess = function(e1) {
      console.log('Updated the user to indexedDB');
    }
    getKeyRequest.onerror = function() {
      console.log('Failed to update the user to indexedDB');
    }
  }
  openRequest.onerror = function() {
    console.log('Failed to open the indexedDB database');
  }
}

/**
 *
 * @returns {Promise<boolean>}
 */
export const removeFcmTokenFromIndexedDb = function() {
  return new Promise((res, rej) => {
    const openRequest = window.indexedDB.open('firebase-messaging-database');
    openRequest.onsuccess = function(e) {
      const instance = e.target.result;

      const transaction = instance.transaction('firebase-messaging-store', 'readwrite');
      const objectStore = transaction.objectStore('firebase-messaging-store');
      const getKeyRequest = objectStore.getAllKeys();
      getKeyRequest.onsuccess = function(e1) {
        const keys = e1.target.result;
        try {
          const deleteRequest = objectStore.delete(keys[0]);

          deleteRequest.onsuccess = function() {
            res(true);
          }

          deleteRequest.onerror = function() {
            res(false);
          }
        } catch (e) {
          res(false);
          console.log('e = ', e);
        }
      }
      getKeyRequest.onerror = function() {
        res(false);
      }
    }
    openRequest.onerror = function() {
      res(false);
    }
  })
}



const removeFcmToken = async (userId, fcmToken) => {
  return await (require('../firebase-config')).db
    .collection("user_tokens")
    .doc(userId)
    .get()
    .then(async (doc) => {
      if (doc.exists) {
        let tokens = doc.data().tokens?.filter((t) => t.token !== fcmToken);

        await removeSafariToken(userId);

        return await (require('../firebase-config')).db
          .collection("user_tokens")
          .doc(userId)
          .update({ tokens: tokens })
          .then(() => true)
          .catch((e) => {
            console.log(e)
            return false;
          });
      }
      return true;
    })
    .catch((e) => {
      console.log(e);
      return false;
    });
};

const logOut = async () => {
  let logout_sucess;
  await (require('../firebase-config')).auth.signOut().then(() => {
    logout_sucess = true;
  });

  try {
    await require('../firebase-config').messaging.deleteToken();
  } catch(e) {

  }

  return logout_sucess;
};

const setDeviceToken = async (token, userId) => {
  const indianTime = await castIndianTime(true);
  const tokenDocumentRef = require('../firebase-config').db.collection("user_tokens")
    .doc(userId);
  const snapshot = await tokenDocumentRef.get();
  let tokens = [];
  if(snapshot.exists) {
    tokens = snapshot.data().tokens ?? [];
  }

  let isThere = tokens.some(c => (c.token === token && c.platform === 'web'));

  let newTokenList = tokens;

  if(!isThere) {
    newTokenList.push({
      created_ts: +indianTime,
      last_seen: +indianTime,
      platform: "web",
      token: token,
    })
  } else {
    newTokenList = tokens.map(item => {
      if(item.token === token) {
        return {
          ...item,
          last_seen: +indianTime
        }
      }
      return item;
    })
  }

  await tokenDocumentRef.set({
    tokens: newTokenList
  }, {merge: true})
    .catch((err) => console.log(err));
};

const refreshNotificationTokens = async (uid) => {
  try {
    return await axios.post('https://us-central1-avian-display-193502.cloudfunctions.net/' + 'refreshNotificationSubscriptionList', {
      uid
    });
  } catch (e) {
    console.log('Notification token is not refreshed - ', e.message);
  }
}

const guestUser = {
  name: 'Guest User',
  email: 'guest@email.com',
  grade: null,
  uid: 'guest-user',
  phone: '+91XXXXXXXXXX',
  is_guest: true,
  profile_url: astronautPic
}

export default function useAuth() {
  const [user, _setUser] = useState(null);
  const [openNameModal, setOpenNameModal] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const router = useRouter();
  const [userHasNoGrade, setUserHasNoGrade] = useState(false);
  const [isUserProTier, setIsUserProTier] = useState(false);
  const [pushyData, setPushyData] = useState(null);
  const [blazeCallAlert, setBlazeCallAlert] = useState(false);
  const [isInstructor, setIsInstructor] = useState(false);
  const [checkedLoggedInStatus, setCheckedLoggedInStatus] = useState(false);
  const [isExternal, setIsExternal] = useState(false);
  const [unreadCareMsgCount, setUnreadCareMsgCount] = useState(0);
  const [closeInstallApp, setCloseInstallApp] = useState(false);
  const [snackbarData, setShowSnackbar] = useState(null);
  const lastUserIdRef = useRef(null);

  const setUser = useCallback((_user) => {
    console.log('_user - ', _user);
    if(_user) {
      return _setUser(_user);
    }
    const storedGrade = localStorage.getItem('guest_grade');
    _setUser({...guestUser, grade: storedGrade});
  }, [])

  useEffect(() => {
    const storedGrade = window.localStorage.getItem('guest_grade');
    _setUser({...guestUser, grade: storedGrade});
  }, [])

  // const setDeviceTokenFn = (token) => {
  //   if (user?.uid) setDeviceToken(token, user?.uid);
  // };

  // useEffect(() => {
  //   const localToken = localStorage.getItem("fcmToken");
  //   const isSet = sessionStorage.getItem("fcmSetToCloud");
  //   console.log('user - ', user, localToken);
  //   if(user?.uid && localToken) {
  //     setDeviceToken(localToken, user?.uid);
  //     sessionStorage.setItem("fcmSetToCloud", true);
  //   }
  // }, [user])

  useEffect(() => {
    if(!user?.uid || user?.uid === 'guest-user') return;
    const unsub = require('../firebase-config').db.collection('users')
      .doc(user?.uid)
      .onSnapshot(snapshot => {
        if(snapshot.exists && (!snapshot.data().name || snapshot.data().name?.trim().length === 0)) {
          setOpenNameModal(true);
        } else {
          setOpenNameModal(false);
        }
      })

    return () => {
      unsub();
    }
  }, [user?.uid])

  useEffect(() => {
    const unsub = require('../firebase-config').auth.onAuthStateChanged(async (user) => {
      if(!user) {
        const guestGrade = localStorage.getItem('guest_grade');
        const skipLandingPage = localStorage.getItem('skipLandingPage');
        console.log('localStorage.clear() - ');
        localStorage.clear();
        localStorage.removeItem("user");
        localStorage.setItem("hideCookie", "true");
        localStorage.setItem("skipLandingPage", skipLandingPage);
        if(guestGrade) localStorage.setItem("guest_grade", guestGrade);
        setUser(null);
        // if(!window.location.pathname.includes('/classroom') && !commonPages.find(c => c.path === window.location.pathname) && !publicPages.find(c => c.path === window.location.pathname)) window.location.replace('/');
        return;
      }
      const snapshot = await require('../firebase-config').db.collection('users').doc(user.uid)
        .get();
      if(snapshot.exists && snapshot.data().grade) {
        console.log('setting user 01 - ', snapshot.data());
        setUser(snapshot.data());
        localStorage.setItem(
          "user",
          JSON.stringify({
            uid: snapshot.data().uid,
            grade: snapshot.data().grade,
            name: snapshot.data().name,
            profile_url: snapshot.data().profile_url,
          })
        );
      }
    })

    const unsub1 = require('../firebase-config').auth.onIdTokenChanged(async (user) => {
      if (!user) {
        // setUser(null);
        nookies.set(undefined, 'token', '', { path: '/' });
      } else {
        const token = await user.getIdToken();
        // setUser(user);
        nookies.set(undefined, 'token', token, { path: '/' });
      }
    });

    return () => {
      unsub();
      unsub1();
    }
  }, [])

  useEffect(() => {
    if(user) {
      castIndianTime(true)
        .then(ist => {
          // /user_state/pustack_app/active_users/' + user?.uid + /client_id
          // /user_state/pustack_app/user_presence/' + user?.uid + /client_id
          require('../firebase-config').rdb.ref('/user_state/pustack_app/active_users/' + user?.uid)
            .set({
              platform: 'web',
              update_time: ist.serverISOString,
            })

          require('../firebase-config').rdb.ref('/user_state/pustack_app/inactive_users/' + user?.uid)
            .set(null)
        })

      require('../firebase-config').rdb.ref('/user_state/pustack_app/active_users/' + user?.uid)
        .onDisconnect()
        .set(null)

      require('../firebase-config').rdb.ref('/user_state/pustack_app/inactive_users/' + user?.uid)
        .onDisconnect()
        .set({
          last_seen: require('firebase').database.ServerValue.TIMESTAMP,
          platform: 'web'
        });


    } else {
      require('../firebase-config').rdb.ref('/user_state/pustack_app/active_users/' + user?.uid).remove();
    }

    return () => {
      require('../firebase-config').rdb.ref('/user_state/pustack_app/active_users/' + user?.uid)
        .onDisconnect()
        .cancel();
    }
  }, [user])

  const clientIdRef = useRef<string>(null);
  const clientPathRef = useRef<string>(null);
  const userPathRef = useRef<string>(null);
  const clientDisconnectRef = useRef<any>(null);
  const userDisconnectRef = useRef<any>(null);

  useEffect(() => {

    if (user && !user?.is_guest && user?.uid !== 'guest-user') {
      // If the client ID doesn't exist, generate and set it
      if (!clientIdRef.current) {
        clientIdRef.current = navigator.platform + '_' + Date.now();
      }

      // Define the paths in the Realtime Database
      userPathRef.current = `/user_state/pustack_app/user_presence/${user.uid}`;
      clientPathRef.current = `${userPathRef.current}/clients/${clientIdRef.current}`;

      // Get a reference to the user's presence in the database
      const userIdRef = require('../firebase-config').rdb.ref(userPathRef.current);

      // Start a transaction to update the user's presence
      userIdRef.transaction((currentData) => {
        // Create a new presence object to set in the database
        let newObj = {...(safeDestructible(currentData))};
        newObj.clients = {
          ...(safeDestructible(newObj.clients)),
          [clientIdRef.current]: {
            platform: 'web',
            update_time: require('firebase').database.ServerValue.TIMESTAMP,
          }
        };

        // Update the user's presence data
        return omitUndefinedKeys({
          ...newObj,
          last_device_disconnected_at: currentData?.last_device_disconnected_at,
          last_device_connected_at: require('firebase').database.ServerValue.TIMESTAMP,
        });
      }).then((result) => {
        if (result.committed) {
          console.log('Transaction was successful!');
        } else {
          console.log('Transaction was not committed.');
        }
      }).catch((e) => {
        console.log('Error in Updating the user\'s presence transaction - ', e);
      });

      // Get references to onDisconnect operations for cleanup
      userDisconnectRef.current = userIdRef.onDisconnect();
      clientDisconnectRef.current = require('../firebase-config').rdb.ref(clientPathRef.current).onDisconnect();

      // Set up onDisconnect to handle disconnections
      userDisconnectRef.current.update({
        last_device_disconnected_at: require('firebase').database.ServerValue.TIMESTAMP,
      });

      clientDisconnectRef.current.remove();

      // Return cleanup functions for the onDisconnect operations
      return () => {
        userDisconnectRef.current.cancel();
        clientDisconnectRef.current.cancel();
      };
    }
  }, [user]);

  useEffect(() => {
    if(!user?.uid || user?.uid === lastUserIdRef.current) return;

    async function run() {
      lastUserIdRef.current = user?.uid;
      try {
        updateUserToIndexedDB(user?.uid);
      } catch(e) {
        console.log('Error in updating the user id in indexedDB');
      }

      document.removeEventListener('mousedown', run);

      console.log('FCM Token Operation');
      await require('../firebase-config').messaging.requestPermission();

      // Refresh the token list
      console.log('Starting refreshing token operation');
      const res = await refreshNotificationTokens(user?.uid);
      console.log('Refresh notification response - ', res);

      const ist = await castIndianTime();

      // Fetch the token and compare it with the local token
      const snapshot = await require('../firebase-config').db.doc('user_tokens/' + user?.uid).get();
      const localToken = await require('../firebase-config').messaging.getToken({vapidKey: VAPIDKEY});

      let tokens = [];

      if(snapshot.exists) {
        tokens = snapshot.data().tokens;
      }

      const token = tokens.find(c => c.token === localToken && c.platform === 'web' && c.app === 'pustack_app');

      // If the token is there
      if(token) {
        console.log('Token is present and valid, updating the last_seen');
        tokens = tokens.map(c => {
          if(c.token === localToken && c.platform === 'web' && c.app === 'pustack_app') {
            return {
              ...c,
              last_seen: +ist
            }
          }
          return c;
        })
        localStorage.setItem('fcmToken', token.token);
        // Update the last_seen
        await require('../firebase-config').db.doc('user_tokens/' + user?.uid)
          .set({tokens}, {merge: true})
        console.log('Updated the last_seen of the token');
        console.groupEnd();
      } else {
        // If not then delete the previous token
        // await require('../firebase-config').messaging.deleteToken();
        // Get the token using getToken()
        console.log('Token was expired');
        console.log('Removing FCM token from indexedDB');
        await removeFcmTokenFromIndexedDb();
        const token = await require('../firebase-config').messaging.getToken({vapidKey: VAPIDKEY});

        await require('../firebase-config').messaging.onTokenRefresh({
          nextOrObserver: async function(token) {
            localStorage.setItem('fcmToken', token);
            await require('../firebase-config').db.doc('user_tokens/' + user?.uid)
              .set({
                tokens: require('../firebase-config').firebase.firestore.FieldValue.arrayUnion({
                  platform: 'web',
                  last_seen: +ist,
                  created_ts: +ist,
                  app: 'pustack_app',
                  token
                })
              }, {merge: true})
          }
        })

        localStorage.setItem('fcmToken', token);
        console.log('New Token fetched and storing it to the server');
        // Store it to the server
        await require('../firebase-config').db.doc('user_tokens/' + user?.uid)
          .set({
            tokens: require('../firebase-config').firebase.firestore.FieldValue.arrayUnion({
              platform: 'web',
              last_seen: +ist,
              created_ts: +ist,
              app: 'pustack_app',
              token
            })
          }, {merge: true})

        console.log('Stored the new FCM token to the server');
        console.groupEnd();
      }

      require('../firebase-config').messaging.onMessage((payload) => {

        if(payload.data && payload.data.belongs_to) {
          if(payload.data.belongs_to !== user?.uid) return;
        }

        console.log('message is here - ', payload);
        let data = payload.data;
        console.log({ payload: payload.data });

        if (data?.notification_type === "session_ping") {
          setPushyData(data);
          setBlazeCallAlert(true);
        } else if (data?.notification_type === "dismiss_ping" || data?.notification_type === "ping_expired") {
          setBlazeCallAlert(false);
        }

        if(data?.notification_type === "dismiss_ringer") {
          setBlazeCallAlert(false);
        }

        if(data?.notification_type === "dismiss_ringer") {
          setBlazeCallAlert(false);
        }

        if(data?.notification_type === "session_accepted") {
          setShowSnackbar({
            title: data?.title,
            description: data?.body,
            type: 'success'
          });
        }
      });
    }

    if('serviceWorker' in navigator) {
      navigator.serviceWorker.onmessage = function(e) {
        if (e.data.type === "ping_expired" || e.data.type === "dismiss_ping") {
          setBlazeCallAlert(false);
        } else if(e.data.type === 'session_ping') {
          setBlazeCallAlert(true);
          setPushyData(e.data.payload.data);
        }

        if(e.data.type === 'redirect') {
          router.push(e.data.url);
        }

        if(e.data.type === "session_accepted") {
          setShowSnackbar({
            title: e.data.payload.data?.title,
            description: e.data.payload.data?.body,
            type: 'success'
          });
        }

        if(e.data.type === "dismiss_ringer") {
          setBlazeCallAlert(false);
        }
      }
    }

    const fcmToken = localStorage.getItem('fcmToken');
    if(fcmToken) {
      run();
    } else {
      document.addEventListener('mousedown', run);
    }

    return () => {
      document.removeEventListener('mousedown', run);
    }
  }, [user]);

  useEffect(() => {

    let unsub = function() {}
    // let path = window.location.pathname;

    // if (path === "/app") {
    // 	return (window.location.href = appGooglePlayLink);
    // }

    console.log('user changes - ', user);

    if (localStorage.getItem("user")) {
      const _user = JSON.parse(localStorage.getItem("user"));
      console.log('setting user 01 - ', _user);
      setUser(_user);
      if(_user && !_user.grade) {
        setUserHasNoGrade(true);
      } else {
        setUserHasNoGrade(false);
      }

      if (localStorage.getItem("closeInstallApp")) {
        setCloseInstallApp(true);
      }

      if (localStorage.getItem("isUserPro")) {
        setIsUserProTier(localStorage.getItem("isUserPro") === "true");
      }

      if (localStorage.getItem("isInstructor")) {
        setIsInstructor(localStorage.getItem("isInstructor") === "true");
      }

      if (localStorage.getItem("isExternalInstructor")) {
        setIsExternal(localStorage.getItem("isExternalInstructor") === "true");
      }
    } else {
      // router.replace('/');
      setIsLoading(false);
    }

    if (user?.uid && !user.is_guest) {
      try {
        unsub = setUserImportantDataFn(user.uid);
        setUnreadMsgCountFn(user);
      } catch (error) {
        setUser(null);
        setIsLoading(false);
      }
    }

    setCheckedLoggedInStatus(true);

    return () => {
      try {
        unsub();
      } catch(e) {
        console.log('e - ', e);
      }
    }
  }, [user?.uid]);

  const setUserImportantDataFn = async (uid) => {
    const res = await userImportantData(uid);

    return res.onSnapshot(async (snapshot) => {
      // console.log('user?.has_rated_app, snapshot.data()?.has_rated_app - ', user?.has_rated_app, snapshot.data()?.has_rated_app)
      // if(user?.has_rated_app !== undefined && snapshot.data()?.has_rated_app !== user?.has_rated_app) return;
      console.log('snapshot - ', snapshot);
      if(!snapshot.exists || snapshot.data().is_deleted) {

        let fcmToken = localStorage.getItem("fcmToken");
        let isTokenRemoved = await removeFcmToken(uid, fcmToken);

        if (isTokenRemoved) {
          let logout_success = await logOut();
          if (logout_success) {
            setUser(null);

            // loadingWrapper();

            const guestGrade = localStorage.getItem('guest_grade');
            console.log('localStorage.clear() - ');
            localStorage.clear();
            localStorage.setItem("hideCookie", true);
            if(guestGrade) localStorage.setItem("guest_grade", guestGrade);

            // window.location = "/";
          }
        }

        return;
      }
      if (snapshot.data() || "") {
        setIsUserProTier(snapshot.data()?.tier === "pro");
        setIsInstructor(snapshot.data()?.is_instructor);
        setIsExternal(snapshot.data()?.is_external_instructor || false);

        let _user = JSON.parse(localStorage.getItem("user"));

        _user = { ..._user, ...snapshot.data() };

        if(!_user.profile_url) _user.profile_url = defaultPic;
        console.log('setting user 01 - ', _user);
        setUser(_user);
        setIsLoading(false);

        localStorage.setItem(
          "user",
          JSON.stringify({
            uid: _user?.uid,
            grade: _user?.grade,
            name: _user?.name,
            profile_url: _user?.profile_url,
          })
        );

        localStorage.setItem(
          "isUserPro",
          JSON.stringify(snapshot.data()?.tier === "pro")
        );
        localStorage.setItem(
          "isInstructor",
          JSON.stringify(snapshot.data()?.is_instructor)
        );
        localStorage.setItem(
          "isExternalInstructor",
          JSON.stringify(snapshot.data()?.is_external_instructor || false)
        );
      } else {
        if (navigator.onLine) {
          setUser(null);
          setIsUserProTier(false);
          setIsInstructor(false);
          const guestGrade = localStorage.getItem('guest_grade');
          console.log('localStorage.clear() - ');
          localStorage.clear();
          localStorage.setItem("hideCookie", true);
          if(guestGrade) localStorage.setItem("guest_grade", guestGrade);
          window.location = "/";
        }
      }
    });
  };

  const setUnreadMsgCountFn = async (_user) => {
    (await getCareMessageCount({ userId: _user?.uid, grade: _user?.grade })).onSnapshot(
      (snapshot) => {
        const count = snapshot.data()?.unread_care_message_count;

        setUnreadCareMsgCount(count);

        if (count > 0) {
          // TODO: Enable setOpenPustackCare on mediaQuery

          // !isSmallScreen && setOpenPustackCare(true);
          // if (!openPustackCare) {
          //   let audio = new Audio(newMsgAudio);
          //   audio.play();
          // }
        }
      },
      (error) => console.log(error)
    );
  };

  return {
    user,
    setUser,
    isLoading,
    userHasNoGrade,
    isUserProTier,
    pushyData,
    blazeCallAlert,
    isInstructor,
    isExternal,
    setBlazeCallAlert,
    openNameModal,
    setOpenNameModal,
    unreadCareMsgCount,
    closeInstallApp,
    snackbarData,
    clientPathRef,
    userPathRef,
    clientDisconnectRef,
    userDisconnectRef,
  };
}
