import {useContext, useEffect, useMemo, useState} from "react";
import {UserContext} from "../../context";

export default function useIsProduction() {
  const [is, setIs] = useState(false);
  const [user] = useContext(UserContext).user;

  useEffect(() => {
    if(!window) return setIs(false);
    const isProduction = !user?.is_instructor && window.location.origin.includes('pustack.com');
    return setIs(isProduction);
  }, [user])

  return is;
}

// export const isProduction = window.location.origin.includes('pustack.com');
