import React, {useState, createContext, useContext} from "react";
import {useRouter} from "next/router";
import {UserContext} from './user-context';

export const PustackProContext = createContext();

export const PustackProContextProvider = ({isOpen: _isOpen, ...props}) => {
  //------------------------------------ constants hooks

  // const [isOpen, setIsOpen] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [user] = useContext(UserContext).user;
  const router = useRouter();

  const isOpen = user && !user.is_guest && (_isOpen || router.query.pro_slider);

  const setIsOpen = (is) => {
    if(!user || user.is_guest) return;
    let query = router.query;
    if(is) {
      delete query.page;
      query.pro_slider = is;
    } else {
      return router.back()
    }
    router.push({
      pathname: router.pathname,
      query
    })
  }

  const isDoubtOpen = user && !user.is_guest && (_isOpen || router.query.doubt_slider);

  const setIsDoubtOpen = (is) => {
    if(!user || user.is_guest) return;
    let query = router.query;
    if(is) {
      delete query.page;
      query.doubt_slider = is;
    } else {
      return router.back()
    }
    router.push({
      pathname: router.pathname,
      query
    })
  }

  return (
    <PustackProContext.Provider
      value={{
        value: [isOpen, setIsOpen],
        doubtValue: [isDoubtOpen, setIsDoubtOpen],
        warning: [showWarning, setShowWarning]
      }}
    >
      {props.children}
    </PustackProContext.Provider>
  );
};

export default PustackProContextProvider;
