import {useEffect, useState} from "react";

/**
 *
 * @returns {function(): boolean}
 */
export function useIsMounted() {
  const [isMounted, setIsMounted] = useState(false);
  // const isMountedRef = useRef(true);
  // const isMounted = useCallback(() => isMountedRef.current, []);

  useEffect(() => {
    setIsMounted(true)
    return () => setIsMounted(false)
  }, []);

  return isMounted;
}
