import {useEffect, useState} from 'react';
import useTimer from './timer';

export default function useIsNight() {
	const [isNight, setIsNight] = useState(false);

	useEffect(() => {
		const d = new Date();
		const currentHour = d.getHours();
		if(currentHour >= 7 && currentHour <= 19) setIsNight(false);
		else setIsNight(true);

		const interval = setInterval(() => {
			const d = new Date();
			const currentHour = d.getHours();
			if(currentHour >= 7 && currentHour <= 19) setIsNight(false);
			else setIsNight(true)
		}, 10000)

		return () => {
			clearInterval(interval);
		}
	}, [])

	return isNight;
}
