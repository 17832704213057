import {fetchAssetsOfChapter, STORY_STATUS} from './StoryList';
import React, {useState, useRef, useEffect, useContext,} from 'react';
import {useMediaQuery} from 'react-responsive';
import {SubjectModalContext, ThemeContext, UserContext} from '../../../context';
import {CircularProgressbarWithChildren} from "react-circular-progressbar";
import {fetchStoryItems, storyEventNames} from '../../../database/story';
import {Tooltip} from '@material-ui/core';
import Image from 'next/image';

export function GradientSVG() {
	const idCSS = "hello";
	const gradientTransform = `rotate(90)`;
	return (
		<svg style={{ height: 0 }}>
			<defs>
				<linearGradient id={idCSS} gradientTransform={gradientTransform}>
					<stop offset="16.29%" stopColor="#9C27B0" />
					<stop offset="41.56%" stopColor="#E91E63" />
					<stop offset="79.56%" stopColor="#FF9800" />
				</linearGradient>
			</defs>
		</svg>
	);
}

export function StoryIcon({story, handleClick, big}) {
	const [loading, setLoading] = useState(false);
	const [progress, setProgress] = useState(0);
	const [,setSelectedStoryIndex] = useContext(SubjectModalContext).selectedStoryIndex;
	const [stories, setStories] = useContext(SubjectModalContext).stories;
	const storyEventRef = useContext(SubjectModalContext).storyEventRef;
	const fetched = useRef(false);
	const [isTranscoding, setIsTranscoding] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [status, setStatus] = useState(STORY_STATUS.LOADED);
	const [user] = useContext(UserContext).user;
	const [unWatched, setUnWatched] = useState(true);
	const isSmallScreen = useMediaQuery({ query: "(max-width: 500px)" });
	const [isDark] = useContext(ThemeContext).theme;

	useEffect(() => {
		function onUpdate(snapshot) {
			const story = snapshot.val() ? snapshot.val() : {};
			const keys = Object.keys(story?.cumulative_views ?? {});
			setUnWatched(!keys.includes(user.uid));

			const status = story?.transcoding_status?.status;

			if(!isNaN(status) && status !== 3) {
				setStatus(STORY_STATUS.IS_TRANSCODING);
			} else {
				setStatus(STORY_STATUS.LOADED);
			}
		}
		require('../../../firebase-config').rdb.ref('stories/collections').child(story.id).on('value', onUpdate)

		return () => {
			require('../../../firebase-config').rdb.ref('stories/collections').child(story.id).off('value', onUpdate);
		}
	}, [story.id, user.uid])

	useEffect(() => {
		if(fetched.current) return
		if(story.items && story.items.length > 0) return;

		setStatus(STORY_STATUS.LOADING);

		fetchStoryItems(story.id, user.grade)
			.then((items) => {
				console.log('Setting story - ');
				setStories(stories => {
					const _stories = [...stories];
					const _storyObjectIndex = _stories.findIndex(c => c.id === story.id);
					const _storyObject = _stories[_storyObjectIndex];
					_stories.splice(_storyObjectIndex, 1, {..._storyObject, fetched: true, items: items});
					return _stories;
				})
				setStatus(STORY_STATUS.LOADED);
				fetched.current = true;
			})
			.catch(err => {
				console.log('err - ', err);
				fetched.current = false;
			});

	} ,[setStories, story.id, story.items, user.grade]);

	useEffect(() => {
		// function handleUploadStoryProgress({detail}) {
		// 	console.log('detail from story progress - ', detail, story.item_name);
		// 	if(detail.storyName !== story.item_name) return console.log('not same story');
		// 	if(detail.status === STORY_STATUS.IS_UPLOADING) {
		// 		setStatus(STORY_STATUS.IS_UPLOADING);
		// 		setProgress(detail.progress);
		// 	} else if(detail.status === STORY_STATUS.IS_TRANSCODING) {
		// 		require().rdb.ref('stories/collections').child(story.id).child('cumulative_views').remove();
		// 		rdb.ref('stories/collections').child(story.id).child('transcoding_status').set({
		// 			status: 0,
		// 		});
		// 		setStatus(STORY_STATUS.IS_TRANSCODING);
		// 	} else if(detail.status === STORY_STATUS.LOADED) {
		// 		setStatus(STORY_STATUS.LOADED);
		// 	} else if(detail.status === STORY_STATUS.LOADING) {
		// 		setStatus(STORY_STATUS.LOADING);
		// 	}
		// }

		function handleStoryViewed({detail}) {
			if(detail.storyId !== story.id) return;
			setUnWatched(false);
			require('../../../firebase-config').rdb.ref('stories/collections').child(story.id).child('cumulative_views').child(user.uid).set({
				watched_on: new Date().toISOString()
			});
		}
		const eventRef = storyEventRef.current;
		// storyEventRef.current.addEventListener(storyEventNames.uploadStoryProgress, handleUploadStoryProgress);
		eventRef.addEventListener(storyEventNames.storyViewed, handleStoryViewed);
		//
		return () => {
		// 	storyEventRef.current.removeEventListener(storyEventNames.uploadStoryProgress, handleUploadStoryProgress);
			eventRef.removeEventListener(storyEventNames.storyViewed, handleStoryViewed);
		}
	}, [storyEventRef, story.item_name, story.id, user.uid])

	async function onClick() {
		if([STORY_STATUS.LOADING, STORY_STATUS.IS_TRANSCODING, STORY_STATUS.IS_UPLOADING].includes(status)) return;
		setStatus(STORY_STATUS.LOADING);
		if(unWatched) {
			setSelectedStoryIndex(c => ({...c, [story.id]: 0}))
		}
		await handleClick(story.id);
		storyEventRef.current.dispatchEvent(storyEventNames.storyViewed, {storyId: story.id});
		setStatus(STORY_STATUS.LOADED);
	}

	const statusMessages = {
		loading: 'Fetching Story',
		isTranscoding: 'Transcoding Story',
		isUploading: 'Uploading Story',
		loaded: story.item_name,
	}

	return (
		<div className="flex-grow-1 flex-shrink-0 flex flex-col items-center justify-center cursor-pointer" onClick={onClick}>
			<div className="aspect-square w-[--size] h-[--size] p-0 rounded-full" style={{
				// background: 'conic-gradient(#9C27B0, #E91E63, #FF9800, #9C27B0)'
				'--size': big ? '110px' : '60px'
			}}>
				<GradientSVG />
				<CircularProgressbarWithChildren
					strokeWidth={4}
					value={status === STORY_STATUS.IS_UPLOADING ? progress : status === STORY_STATUS.LOADED && !unWatched ? 0 : 100}
					classes={{
						root: [STORY_STATUS.IS_TRANSCODING, STORY_STATUS.LOADING].includes(status) ? 'animate-spin' : '',
					}}
					styles={{
						path: { stroke: `url(#hello)`, height: "100%" },
						trail: {
							stroke: isDark ? "rgba(255,255,255,0.15)" : "rgba(0,0,0,0.15)"
						}
					}}
				>
					<div className="rounded-full bg-[transparent] p-1.5">
						<div className="bg-gray-700 flex relative justify-center items-center  rounded-full overflow-hidden">
							<Image className="w-full h-full object-cover" src={fetchAssetsOfChapter(story.item_background_image).bgImage} alt=""/>
							<Image className="w-[50%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" src={fetchAssetsOfChapter(story.item_background_image).image} alt=""/>
						</div>
					</div>
				</CircularProgressbarWithChildren>
			</div>
			<span className={"mt-1 text-opacity-80 text-gray-900 dark:text-white " + (big ? 'text-[26px] ' : 'text-sm ')}>{story.item_name}</span>
		</div>
	)
}
