import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import ReactDOM from 'react-dom';
import {IntroContext, UserContext} from "../../context";
import {useIsMounted} from '../../hooks/isMounted'
import Dialog from "@material-ui/core/Dialog";
import { useMediaQuery } from "react-responsive";
import Image from 'next/image';
import {getAvailableGrades} from '../../helpers';
import PuStackWhiteLogo from "../../public/assets/images/logoDark.webp";
import comet from "../../public/assets/onboarding/comet.svg";
import gulu from "../../public/assets/onboarding/gulu.svg";
import mars from "../../public/assets/onboarding/mars.svg";
import controlRoom from "../../public/assets/onboarding/control_room.svg";
import {useRouter} from 'next/router';

export default function ChooseGrade() {
	const [_showGradeChange, setShowGradeChange] = useContext(IntroContext).showGradeChange;
	const [user, setUser] = useContext(UserContext).user;
	const isMounted = useIsMounted();
	const isProduction = true;
	const isSmallScreen = useMediaQuery({ query: "(max-width: 500px)" });
	const router = useRouter();
	const [showLandingPage] = useContext(IntroContext).showLandingPage;

	const showGradeChange = useMemo(() => {
		return !showLandingPage && (!router.pathname.includes('/about') && !router.pathname.includes('/sitemap')) && (router.pathname.includes('/') || router.pathname.includes('/blaze') || router.pathname.includes('/classes')) && _showGradeChange;

	}, [_showGradeChange, router.pathname, showLandingPage])


	const setUserGrade = useCallback((grade) => {
		if(grade) localStorage.setItem('guest_grade', grade);
		setUser(c => ({...c, grade}));
	}, [setUser]);

	const activeUserGrade = user?.grade;

	useEffect(() => {
		if(user?.grade) {
			setShowGradeChange(false);
		}
	}, [user?.grade])

	const closeNotAllowed = useMemo(() => !user?.grade && user?.is_guest, [user])


	return (
		showGradeChange && isMounted && ReactDOM.createPortal(
			<Dialog
				onClose={() => {
					if(!closeNotAllowed) setShowGradeChange(false)
				}}
				aria-labelledby="simple-dialog-title"
				open={showGradeChange}
				// open={true}
				className="pro-warning welcome-dialog-wrapper"
				PaperProps={{
					className: 'choose-grade-paper',
					style: {
						width: '800px',
						height: '500px',
						boxShadow: '0 5px 50px #ffffff30'
					}
				}}
				BackdropProps={{
					style: {
						background: 'rgba(0,0,0,.9)'
					}
				}}
			>
				<div className="slide__page">
					<div className="fourth__page__inner">
						<div className="stars__background">
							<div className="stars"></div>
							<div className="twinkling"></div>
						</div>

						<Image alt={"Pustack"} width={100} height={100} src={PuStackWhiteLogo} style={{
							zIndex: 2331223123,
							position: 'relative',
							width: '200px',
							margin: '20px auto',
							display: 'block'
						}} />
						<div className="top__area">
							<h6 style={{
								marginLeft: 0,
								width: '100%',
								textAlign: 'center',
								fontWeight: 600,
								fontSize: '22px'
							}}>Select Your Grade</h6>
						</div>
						<div className="select__classes" style={{
							gridTemplateColumns: isSmallScreen ? '1fr' : '1fr 1fr',
							minWidth: isSmallScreen ? '300px' : '385px'
						}}>
							{getAvailableGrades(null, true, isProduction).map(({ planet, grade, enableToSelect, value }) => (
								<div className={"class__item" + ((isProduction ? enableToSelect : true) ? '' : ' disabled') + (activeUserGrade === value ? ' active' : activeUserGrade ? ' not-active' : '')} onClick={() => {
									if(isProduction ? enableToSelect : true) setUserGrade(value);
									setTimeout(() => {
										setShowGradeChange(false);
									}, 1000)
								}} key={value}>
									<Image height={100} width={100} src={planet} alt="planet2" draggable={false} />
									<h6>{grade}</h6>
								</div>
							))}
							{/*[*/}
							{/*// { planet: planet1, grade: "Class 2", value: "class_2" },*/}
							{/*{ planet: planet4, grade: "Class 9", value: "class_9" },*/}
							{/*{ planet: planet6, grade: "Class 10", value: "class_10" },*/}
							{/*]*/}
						</div>

						<Image height={100} width={100} src={comet} alt="comet" className="comet" />
						<Image height={100} width={100} src={comet} alt="comet2" className="comet2" />

						<Image height={100} width={100} src={gulu} alt="gulu" className="gulu" draggable={false} />
						<Image height={100} width={100} src={mars} alt="mars" className="mars" style={{animationName: 'marsAnimationDesktopView'}} draggable={false} />
						<Image height={100} width={100}
						       src={controlRoom}
						       alt="control"
						       className="control"
						       draggable={false}
						/>
					</div>
				</div>
			</Dialog>,
			document.getElementById('modal')
		)
	)
}
