import firebase from 'firebase';
import {EventNameString, EventParams} from '@firebase/analytics-types';

interface CustomParams {[key: string]: any}

type ScreenNames =
		'blaze_subject_selection_screen'
	| 'add_blaze_session_details_screen'
	| 'blaze_call_screen'
	| 'scholarship_criteria_screen'
	| 'scholarship_form_screen'
	| 'scholarship_form_submitted_screen';

interface IEventParams extends CustomParams {
	method?: 'phone' | 'google' | 'facebook' | 'apple';
}

type JourneyEventNames = EventNameString;

type AuthenticationJourneyEventNames = JourneyEventNames
	| 'pustack_event_is_on_signup_screen'
	| 'pustack_event_has_sent_otp'
	| 'pustack_event_has_verified_otp'
	| 'pustack_event_has_connected_social'
	| 'pustack_event_has_selected_grade'
	| 'student_is_on_home_screen';

type ProSubscriptionJourneyEventNames = JourneyEventNames | 'has_shown_pro_plans';

type BlazeSessionCreationJourneyEventNames = JourneyEventNames | 'on_create_blaze_session_button_tap' | 'has_requested_blaze_session';

type BlazeSessionEndJourneyEventNames = JourneyEventNames | 'on_end_session_button_tap' | 'has_rated_session' | 'has_ended_session';

type BlazeSessionCallJourneyEventNames = JourneyEventNames | 'blaze_call_disconnected' | 'accepted_blaze_call' | 'rejected_blaze_call' | 'received_blaze_call';

type ScholarshipJourneyEventNames = JourneyEventNames;

class Journey<T> {
	protected loggedEvents: (T | JourneyEventNames)[] = [];
	protected analytics: firebase.analytics.Analytics;
	// public logEventWithDebounce: (eventName: T | JourneyEventNames, params?: IEventParams | null, lastEventSequence?: T | JourneyEventNames) => void;

	constructor() {
		this.analytics = require('../../firebase-config').analytics;
		// this.logEventWithDebounce = this._logEventWithDebounce(5000).bind(this);
	}

	reset() {
		this.loggedEvents = [];
	}

	isEventLogged(eventName: AuthenticationJourneyEventNames) {
		return this.loggedEvents.some(c => c === eventName);
	}

	isLastSequence(lastSequenceEventName: T | JourneyEventNames) {
		return !lastSequenceEventName || this.loggedEvents.at(-1) === lastSequenceEventName;
	}

	isInSequence(sequenceEventName: T | JourneyEventNames) {
		return this.loggedEvents.some(c => c === sequenceEventName);
	}

	logEvent(
		eventName: 'purchase' | 'refund',
		params: {
			value?: EventParams['value'];
			currency?: EventParams['currency'];
			transaction_id: EventParams['transaction_id'];
			tax?: EventParams['tax'];
			shipping?: EventParams['shipping'];
			items?: EventParams['items'];
			coupon?: EventParams['coupon'];
			affiliation?: EventParams['affiliation'];
			[key: string]: any;
		},
		lastEventSequence?: T | JourneyEventNames
	): void;

	logEvent(
		eventName: 'add_to_cart' | 'remove_from_cart',
		params: {
			currency?: EventParams['currency'];
			value?: EventParams['value'];
			items?: EventParams['items'];
			[key: string]: any;
		},
		lastEventSequence?: T | JourneyEventNames
	): void;

	logEvent(
		eventName: 'screen_view',
		params: {
			app_name: string;
			screen_name: ScreenNames;
			app_id?: string;
			app_version?: string;
			app_installer_id?: string;
			[key: string]: any;
		},
		lastEventSequence?: T | JourneyEventNames
	): void;

	logEvent(
		eventName: T,
		params?: {
			[key: string]: any;
		},
		lastEventSequence?: T | JourneyEventNames
	): void;

	logEvent(eventName: T | JourneyEventNames, params?: IEventParams | null, lastEventSequence?: T | JourneyEventNames) {
		if(lastEventSequence && !this.isLastSequence(lastEventSequence)) return;
		// @ts-ignore
		this.analytics.logEvent(eventName, params);
		this.loggedEvents.push(eventName);
	}

	logEventWithDebounce(timer: number = 5000): (eventName: T | JourneyEventNames, params?: IEventParams | null, lastEventSequence?: T | JourneyEventNames) => void {
		let timeout;
		const analytics = this.analytics;
		const loggedEvents = this.loggedEvents;
		const isLastSequence = this.isLastSequence;
		const _this = this;
		return function (eventName: T | JourneyEventNames, params?: IEventParams | null, lastEventSequence?: T | JourneyEventNames) {
			if(timeout) return;
			if (lastEventSequence && !isLastSequence.bind(_this, lastEventSequence).call()) return;
			timeout = true;
			// @ts-ignore
			analytics.logEvent(eventName, params);
			loggedEvents.push(eventName);
			setTimeout(() => {
				timeout = false;
			}, timer)
		}
	}
}

export class AuthenticationJourney extends Journey<AuthenticationJourneyEventNames> {

	logEvent(eventName: AuthenticationJourneyEventNames, params?: IEventParams | null, previousEventSequence?: AuthenticationJourneyEventNames) {
		if(this.isEventLogged(eventName)) return;
		if(previousEventSequence && !this.isInSequence(previousEventSequence)) return;

		// @ts-ignore
		this.analytics.logEvent(eventName, params);
		this.loggedEvents.push(eventName);
	}
}

export class ProSubscriptionJourney extends Journey<ProSubscriptionJourneyEventNames> {}

export class BlazeSessionCreationJourney extends Journey<BlazeSessionCreationJourneyEventNames> {}

export class BlazeSessionEndJourney extends Journey<BlazeSessionEndJourneyEventNames> {}

export class ScholarshipJourney extends Journey<ScholarshipJourneyEventNames> {}

export class BlazeSessionCallJourney extends Journey<BlazeSessionCallJourneyEventNames> {

	// @ts-ignore
	logEvent(
		eventName: 'blaze_call_disconnected',
		params: {
			method: 'call_disconnected_by_self' | 'call_disconnected_by_remote' | 'call_disconnected_grace_period_exceeded'
		},
		lastEventSequence?: BlazeSessionCallJourneyEventNames
	): void;
}
