import React, {useState, createContext, useEffect, useMemo, useCallback} from "react";
import {useMediaQuery} from "react-responsive";
import {useRouter} from "next/router";

export const BlazeSessionContext = createContext();

const BlazeContextProvider = (props) => {
  const [tabIdx, setTabIdx] = useState(0);
  const [openPage, setOpenPage] = useState(0);
  const [openChat, setOpenChat] = useState(false);
  const isTabletScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const [sessionId, setSessionId] = useState(null);
  const [openPages, setOpenPages] = useState(false);
  const [callStartTs, setCallStartTs] = useState(null);
  const [subjectColors, setSubjectColors] = useState(null);
  const [instructorRating, setInstructorRating] = useState(0);
  const [studentActivity, setStudentActivity] = useState(null);
  const [sessionSelected, setSessionSelected] = useState(null);
  const [openRatingModal, setOpenRatingModal] = useState(false);
  const [ratingModalDetails, setRatingModalDetails] = useState(null);
  const [requestedSessions, setRequestedSessions] = useState(null);
  const [ongoingSessions, setOngoingSessions] = useState(null);
  const [completedSessions, setCompletedSessions] = useState(null);
  const [currentSessionUnreadCount, setCurrentSessionUnreadCount] = useState(0);
  const [_openBookingPopup, _setOpenBookingPopup] = useState(false);
  const [blazeUnreadCount, setBlazeUnreadCount] = useState({});
  const [freePeriod, setFreePeriod] = useState(480);
  const [maxTimePerDay, setMaxTimePerDay] = useState(600);
  const [blazePageLoading, setBlazePageLoading] = useState(true);
  const router = useRouter();

  const openBookingPopup = useMemo(() => {
    if(!isTabletScreen) return _openBookingPopup;
    return router.query.create_blaze_session === 'true';
    }, [isTabletScreen, _openBookingPopup, router.query.create_blaze_session]);

  const setOpenBookingPopup = useCallback((isOpen) => {
    if(!isTabletScreen) return _setOpenBookingPopup(isOpen);

    if(isOpen) return router.push({
      pathname: router.pathname,
      query: {
        ...router.query,
        create_blaze_session: true
      }
    });
    const _query = {
      ...router.query,
      create_blaze_session: undefined
    };

    delete _query.create_blaze_session;

    return router.push({
      pathname: router.pathname,
      query: _query
    });
  }, [isTabletScreen, router])

  useEffect(() => {
    async function run() {
      const snapshot = await require('../../firebase-config').db.collection('blaze_dev/collections/admin').doc('config')
        .get();

      if(snapshot.exists) {
        if(snapshot.data().grace_period) setFreePeriod(snapshot.data().max_time_per_day);
        if(snapshot.data().max_time_per_day) setMaxTimePerDay(snapshot.data().max_time_per_day + snapshot.data().grace_period);
      }
    }

    run();
  }, [])

  return (
    <BlazeSessionContext.Provider
      value={{
        tabIdx: [tabIdx, setTabIdx],
        openChat: [openChat, setOpenChat],
        openPage: [openPage, setOpenPage],
        sessionId: [sessionId, setSessionId],
        openPages: [openPages, setOpenPages],
        callStartTs: [callStartTs, setCallStartTs],
        subjectColors: [subjectColors, setSubjectColors],
        sessionSelected: [sessionSelected, setSessionSelected],
        studentActivity: [studentActivity, setStudentActivity],
        openRatingModal: [openRatingModal, setOpenRatingModal],
        instructorRating: [instructorRating, setInstructorRating],
        requestedSessions: [requestedSessions, setRequestedSessions],
        ongoingSessions: [ongoingSessions, setOngoingSessions],
        completedSessions: [completedSessions, setCompletedSessions],
        ratingModalDetails: [ratingModalDetails, setRatingModalDetails],
        openBookingPopup: [openBookingPopup, setOpenBookingPopup],
        currentSessionUnreadCount: [
          currentSessionUnreadCount,
          setCurrentSessionUnreadCount,
        ],
        blazeUnreadCount: [blazeUnreadCount, setBlazeUnreadCount],
        freePeriod: [freePeriod, setFreePeriod],
        maxTimePerDay: [maxTimePerDay, setMaxTimePerDay],
        blazePageLoading: [blazePageLoading, setBlazePageLoading]
      }}
    >
      {props.children}
    </BlazeSessionContext.Provider>
  );
};

export default BlazeContextProvider;
